@import '~swiper/swiper-bundle';
@import '~@vime/core/themes/default.css';
@import '~@vime/core/themes/light.css';
@import '~viewerjs/dist/viewer.css';
@import '~ngx-drag-to-select/ngx-drag-to-select.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import 'animate.css';
@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../src/assets/theme.css';
@import '../node_modules/primeng-lts/resources/primeng.min.css';

// @font-face {
//     font-family: 'Roboto-Condensed-Regular';
//     src:  url('./assets/fonts/Roboto-Condensed-Regular.ttf.woff') format('woff'), url('./assets/fonts/Roboto-Condensed-Regular.ttf.svg#Roboto-Condensed-Regular') format('svg'), url('./assets/fonts/Roboto-Condensed-Regular.ttf.eot'), url('./assets/fonts/Roboto-Condensed-Regular.eot?#iefix') format('embedded-opentype'); 
//     font-weight: normal;
//     font-style: normal;
// }
.viewer-backdrop {
    background: #333;
}
:root {
    --animate-duration: .35s !important;
}
.munu_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    & > * {
      &:not(.split) {
        width: 48.5%;

        &:first-of-type {
            padding-right: 5px !important;
        }
        &:last-of-type {
            padding-left: 5px !important;
        }
      }
    }
    .split {
      font-size: 20px;
      line-height: 20px;
      color: #686868;
    }
}

.next_line {
    width: 100%;

    &:not(.rd_next_line) {
        & + .next_line {
            height: 18px !important;
        }
    }
}
.upload_error_icon {
    font-size: 12px !important;
    line-height: 12px !important;
    width: 12px !important;
    height: 12px !important;
    color: #c0392b;
}

.without_published {
    opacity: 0.5 !important;
}
.platform_consolid {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin: 4px;
    border-radius: 12px;
    background: #FFF;

    .post_tab_count {
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 300 !important;
        line-height: 20px !important; 
        padding: 0 2.5px !important;
        margin-left: 3px !important;
        border-radius: 4px;
  
        &:last-of-type {
          margin-right: 3px !important;
        }
  
        &.all {
          color: #000 !important;
          
          // &.active {
          //   -webkit-box-shadow: 0px 0px 6px #000;
          //   box-shadow: 0px 0px 6px #000;
          // }
        }
        &.publ {
          color: #209653 !important;
          // &.active {
          //   -webkit-box-shadow: 0px 0px 6px #209653;
          //   box-shadow: 0px 0px 6px #209653;
          // }
        }
        &.live {
          color: #FF6A6A !important;
          // &.active {
          //   -webkit-box-shadow: 0px 0px 6px #FF6A6A;
          //   box-shadow: 0px 0px 6px #FF6A6A;
          // }
        }
        &.wait {
          color: #686868 !important;
          // &.active {
          //   -webkit-box-shadow: 0px 0px 6px #686868;
          //   box-shadow: 0px 0px 6px #686868;
          // }
        }
  
        &.active {
          text-decoration: underline;
          font-weight: 700 !important;
        }
      }
}
body {
    .scheme_item {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 0 10px !important;
        line-height: 32px !important;
        height: 32px !important;

        &.active {
            color: #1877F2;
        }
    
        .small_scheme_icon {
            font-size: 18px;
            line-height: 18px;
            width: 18px;
            height: 18px;
    
            &.red {
                color: #c0392b;
            }
            &.green {
                color: #219653;
            }
        }
    
        .mat-icon {
            margin-right: 5px !important;
    
            & + .mat-icon {
                margin-right: 0 !important;
            }
        }
    
        &.scheme_item_create {
            line-height: 24px;
            height: 24px;
            font-family: 'Roboto Condensed', sans-serif !important;
        }
    
        .show_scheme_hover {
            visibility: hidden;
            opacity: 0;
        }
    
        &:hover {
            .show_scheme_hover {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
.c-move {
    cursor: move !important;
}
.dubl_scen {
    background-color: #7b8fff !important;
}
// .p-skeleton {
//     background-color: #c0e6e6;
// }
.cst_active_select_option {
    background: #2980b9 !important;
    color: #fff !important;
}
.reports_white_input {
    border: 1px solid transparent !important;
    border-radius: 3px !important;
    padding: 2px 5px;

    &::placeholder {
        color: #C9C9C9 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }

    &:hover { 
        background: #2f2f2f !important;
        border-color: #3C3C3C !important;
    }
    &:focus { 
        background: transparent !important;
        border-color: #1877F2 !important;
    }
}
.select_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px 10px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #e6e6e6;
}
.no_external_item {
    background: rgba(157, 51, 51, 0.39) !important; 
}

.regular > .param_info,
.param_value {
    &:hover {
        .c_u_date {
            display: flex;
        }
    }
}
.with_dates {
    position: relative; 

    .c_u_date {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        font-weight: 300;
        font-size: 8px;
        line-height: 8px;
        color: #686868;
        
        align-items: center;

        mat-icon {
            font-size: 8px;
            line-height: 8px;
            width: 8px;
            height: 8px;
            margin-right: 2.5px;
        }
    }
}
.profile {
    position: relative;

    .outlet_info {
        position: relative;
    }

    .date_published {
        position: relative;
    }

    .c_u_date {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        font-weight: 300;
        font-size: 8px;
        line-height: 8px;
        color: #686868;
        
        align-items: center;

        mat-icon {
            font-size: 8px;
            line-height: 8px;
            width: 8px;
            height: 8px;
            margin-right: 2.5px;
        }
    }

    .server_id_line {
        // display: none;
        visibility: hidden;
        opacity: 0;
        // position: absolute;
        // bottom: 1px;
        // left: 10px;
        width: 100%;
        font-weight: 300;
        font-size: 8px;
        line-height: 8px;
        color: #686868;
    }
    .server_id_line_new {
        display: none;
        // visibility: hidden;
        // opacity: 0;
        position: absolute;
        bottom: 1px;
        left: 10px;
        height: 8px;
        
        span {
            font-weight: 300;
            font-size: 8px;
            line-height: 8px;
            color: #686868;
        }
    }
    .id_line {
        display: none;
        position: absolute;
        bottom: 1px;
        right: 1px;
        font-weight: 300;
        font-size: 8px;
        line-height: 8px;
        color: #686868;

        span {
            &:not(.sep_slash) {
                cursor: pointer;
            }
        }

        .sep_slash {
            margin: 0 2.5px;
        }
    }
    .no_e_c_id {
        position: absolute;
        top: 1px;
        right: 1px;
        display: flex;
        align-items: center;

        mat-icon {
            width: 12px;
            height: 12px;
            font-size: 12px;
            line-height: 12px;
            color: #c0392b;
        }
    }
    &:hover {
        .c_u_date {
            display: flex;
        }
        .server_id_line {
            visibility: visible;
            opacity: 1;
        }
        .server_id_line_new {
            display: flex;
        }
        .id_line {
            display: block;
        }
    }
}
.not_show_scen {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
.white_legacy_select .mat-select-value-text {
    color: #fff;
}
.white_legacy_select .mat-form-field-underline {
    background-color: rgb(248, 248, 248, 0.42);
}
.white_legacy_select .mat-form-field-ripple {
    background-color: #fff;
}

.white_legacy_select {
    input {

        &::placeholder {
            color: #C9C9C9 !important;
            font-size: 12px !important;
            font-weight: 400 !important;
        }
    }
}

.white_legacy_select .mat-form-field-label {
    color: #fff;
}

.white_legacy_select {
    .mat-datepicker-toggle {
        color: rgba(255, 255, 255, 0.54) !important;
    }

    .mat-date-range-input-separator {
        color: rgba(255, 255, 255, 0.87) !important;
    }
}

.white_legacy_select.mat-primary .mat-select-arrow{
    color: #fff;
}
.white_legacy_select.mat-focused .mat-form-field-ripple {
    background-color: #fff;
}

.white_legacy_select.mat-focused .mat-form-field-label {
    color: #fff;
}

.white_legacy_select.mat-focused.mat-primary .mat-select-arrow{
    color: #fff;
}
.white_legacy_select.mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(255, 255, 255, 0.12);
}
.white_legacy_select.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(255,255,255,.55);
}
.white_legacy_select.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: rgba(255, 255, 255, 1) !important;
}
.white_legacy_select.mat-form-field-appearance-outline .mat-form-field-label.mat-empty {
    color: rgba(255, 255, 255, 0.12);
}
.white_legacy_select.mat-form-field-appearance-outline:hover .mat-form-field-label.mat-empty {
    color: rgba(255, 255, 255, 0.55);
}
.white_legacy_select.mat-form-field-appearance-outline:hover .mat-form-field-label:not(.mat-empty) {
    color: rgba(255, 255, 255, 1);
}
.white_legacy_select.mat-form-field-appearance-outline .mat-form-field-infix {
    // padding: 9.5px 0 9.5px 0;
    border-top: 0 !important;
}
.white_legacy_select.mat-form-field-appearance-outline .mat-form-field-label {
    top: 2.1375em;
}
.white_legacy_select.mat-form-field-appearance-outline .mat-select-value {
    color: #fff;
}
.white_legacy_select.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
    transform: translateY(-1.2em) scale(0.75);
}
// .mat-form-field-appearance-outline .mat-form-field-label {
//     margin-top: -0.6em;
// }
.white_legacy_select.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
    color: rgba(255, 255, 255, 1) !important;
}
.white_legacy_select.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
}
.white_legacy_select.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: .45em !important;
}
.no_padding_dialog {
    .mat-dialog-container {
        padding: 0 !important;
        // padding-left: 0 !important;
        // padding-right: 0 !important;
    }
}
mat-chip {
    .try_scen {
        visibility: hidden;
        opacity: 0;
        width: 14px;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
        margin-left: 2.5px;
        
        &.is_in_queue {
            color: rgb(209, 143, 21);
        }
        &.is_running {
            color: rgb(0, 204, 255) !important;
        }
    }
    &:hover {
        .try_scen {
            visibility: visible;
            opacity: 1;
        }
    }
}
// .light {
//     .player.video {
//       background-color: #fff !important;

//       video {
//         background-color: #fff !important;
//       }
//     }
// }
.cond {
    font-family: 'Roboto Condensed', sans-serif;
}
.w8_chip {
    background: #0d5f82 !important; 
}
.btn_w8 {
    border: none;
    outline: none;
    border-radius: 3px;
    background: #0d5f82; 
    height: 28px; 
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.btn_w8_24 {
        height: 24px; 
        padding: 0 6px;
    }

    mat-icon {
        margin-right: 5px;
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        height: 20px;
    }
}
.number_mat {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.has_cst_outlets_fil {
    .mat-dialog-container {
        overflow: visible;
    }
}
.new_priority_line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    min-width: 5px;
    height: 100%;
    -webkit-transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
    -o-transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
    transition: all .3s cubic-bezier(.43,.195,.02,1) !important;

    &.br-4 {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    
    &.br-6 {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    &.Urgent{
        background: #DB0000;
    }
    &.High{
        background: #F56718;
    }
    &.Middle{
        background: #219653;
    }
    &.Low{
        background: #2F80ED;
    }
    &.No{
        background: #D6D6D6;
    }
  }
.chat_timestamp {
    display: inline-flex;
    align-items: center;
    span {
        font-size: 10px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important; 

        &.black {
            color: #000000 !important;
        }
        &.silver {
            color: #686868 !important;
        }
    }
}
.p-e-none {
    pointer-events: none;
}
code {
    background-color: #2b2b2b;
    font-family: monospace;
    border-radius: 3px;
    padding: 2px 4px;
    display: block;
    overflow: auto;
    color: #fff;
}
.op-0 {
    opacity: 0;
    pointer-events: none;
}
div.profile_preview_img,
div.preview_img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.single_tooltip {
    .updated_at_tool {
        display: flex;
        margin-top: 10px;
        .updated_at_tool_title {
            font-size: 12px;
            color: #686868;
            font-weight: 300;   

        }
        .updated_at_tool_info {
            margin-left: 5px;
            span {
                display: block;
                color: #000;
                font-weight: 300;
                font-size: 12px;

                b {
                    font-weight: 500;
                }

                & + span {
                    margin-top: 3px;
                }
            }
        }
    }
}
.notes_count {
    position: relative;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    mat-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 38px;
        height: 38px;
        font-size: 38px;
        line-height: 38px;
        color: #2c3e50;
    }

    span {
        left: calc(100% - 10px);
        top: -6px;
        z-index: 2;
        position: absolute;
        background: #e5e5e5;
        border: 1px solid #c03a2b;
        border-radius: 8px;
        height: 14px;
        padding: 0 3px;
        min-width: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c03a2b;
        font-size: 11px;
        line-height: 11px;
        font-weight: 600;
    }

    &:hover {

        span {
            background: #fff;
        }
    }

    &.card_notes {

    }
    &.card_notes_mob {
        height: 20px;
        width: 20px;

        span {
            left: calc(100% - 8px);
            top: -8px;
        }
        mat-icon {
            width: 22px;
            height: 22px;
            font-size: 22px;
            line-height: 22px;
        }
    }
    &.file_notes {
        height: 24px;
        width: 24px;

        span {
            left: calc(100% - 10px);
            top: -6px;
        }
        mat-icon {
            width: 26px;
            height: 26px;
            font-size: 26px;
            line-height: 26px;
        }
    }
}

.date_and_time_label {
    margin-top: 10px;
    transform: translateY(10px);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #686868;
}
.date_and_time_c_w {
    display: flex;
    align-items: center;

    .date_picker_cst {
        .mat-form-field-infix {
            width: 130px;
        }
    }

    .user_timezone {
        background: #FFFCFC;
        border-radius: 12px;
        height: 24px;
        padding: 0 14px;
        margin-left: 20px;
        position: relative;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 35px;
            top: 50%;
            transform: translateY(-50%);
            background: #D3D3D3;
            z-index: 2;
            left: -9.5px;
        }

        span {
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            &:first-of-type {
                color: #686868;
            }
            &:last-of-type {
                color: #000;
                margin-left: 3px;

                &::first-letter {
                    text-transform: uppercase;
                }

            }
        }
    }

    svg {
        fill: #2F80ED;
    }

    .ngx-timepicker-field-example {
        position: relative;
        display: flex;
        align-items: flex-end;
        margin-left: 20px;
        padding-bottom: 6px;
        transform: translateY(-3px);

        &::after {
            transition: all 600ms cubic-bezier(0.25, 0.8, 0.25, 1);
            position: absolute;
            content: "";
            height: 1px;
            background: rgba(0,0,0,.42);
            left: 0;
            right: 0;
            bottom: 0;
        }

        &:hover {
            &::after {
                height: 2px;
            }
        }
        
        .ngx-timepicker {
            border-bottom: none !important;

            .ngx-timepicker-control {
                width: 45px;
            }

            .ngx-material-timepicker-toggle {
                span {
                    width: 24px;
                    height: 24px;
                }
            }

            .ngx-timepicker-control__arrows {
                top: -4px !important
            }
            .ngx-timepicker-control__input {
                background: transparent !important;
            }
        }
    }
}
.preset_form {
    position: relative;

    & + .counter_block {
        margin-left: 10px;
    }

    .simple_filter_snippet {
        padding: 7px 8px;
        background: #E5E5E5;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        cursor: pointer;


        .name {
            padding-left: 8px;
            line-height: 1;
            font-size: 12px;
        }

        svg.arrow {
            margin-left: 7px;
            width: 10px;
            height: 10px;
        }

    }

    .preset_filter {
        position: absolute;
        z-index: -1;
        visibility: hidden;
        bottom: -20px;
        right: 0;
    }
      
}

.fl {
    &::first-letter {
        text-transform: uppercase;
    }
}
.chip_splash {
    width: 1px;
    height: 15px;
    background: #c1c1c1;
    margin-left: 5px;
}
.small_chip_out_label {
    color: #647C64;
    font-weight: 300;
    font-size: 10px;
}

.mat-chip {
    & + .chip_out_label {
        margin-left: 15px;
    }
    & + .small_chip_out_label {
        margin-left: 5px;
    }
}

.chip_out_label {
    color: #647C64;
    font-weight: 500;
    font-size: 12px;
}
  
.user_chip {
    padding-left: 0 !important;
}
.mob_card_backdrop {
    background: rgba(0, 0, 0, 0.29);
    backdrop-filter: blur(0.75px);
    top: 0;
    
    & + .cdk-global-overlay-wrapper {
        align-items: flex-end !important;
        padding-top: 100px !important;
        height: calc(100% - 100px) !important;

        .mat-dialog-container {
            background: transparent !important;
        }
    }
}

@media (min-width: 1000px) {
    .parameters_modal_backdrop {
    
        & + .cdk-global-overlay-wrapper {
            .without_paddings_modal {
                width: 80vw;
                height:calc(100vh - 170px);
                align-items: flex-start;
    
                .mat-dialog-container {
                    height: auto !important;
                }
            }
        }
    }
}

.default_trans {
    -webkit-transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
    -o-transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
    transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
}
.d-block {
    display: block;
}
.cp {
    cursor: pointer;
}
.cm {
    cursor: move;
}
.ve_interface_backdrop {
    background: transparent;
    // background: rgba(0, 0, 0, 0.29);
    // -webkit-backdrop-filter: blur(0.75px);
    // backdrop-filter: blur(0.75px);
    // top: 56px !important;
    
    & + .cdk-global-overlay-wrapper {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        // padding-top: 56px !important;
        height: 100% !important;

        .mat-dialog-container {
            padding: 0 !important;
            width: 430px;
            padding: 0 !important;
            border-radius: 0;
            height: 100vh;
            background: #383838 !important;
            border-right: 1px solid #686868;

            .video_editor_title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 24px;
                border-bottom: 1px solid #686868;
                width: 100%;
                margin: 0;
                padding: 0;

                span {
                    padding: 0 7px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #fff;
                }
                mat-icon {
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    line-height: 20px;
                    color: #fff;
                }
            }
        }
    }
}
.mob_interface_backdrop {
    background: rgba(0, 0, 0, 0.29);
    -webkit-backdrop-filter: blur(0.75px);
    backdrop-filter: blur(0.75px);
    // top: 56px !important;

    &:not(.desk_interface_backdrop) {

        & + .cdk-global-overlay-wrapper {
            align-items: flex-end !important;
            // padding-top: 56px !important;
            height: 100% !important;
        }
    }
    
}
.mob_interface_panel {
    &:not(.desk_interface_panel) {
        width: 100% !important;
        max-width: 100% !important;
    }

    &.desk_interface_panel {
        width: 450px;

        .mobile_scrollable_int  {
            height: auto !important;
        }
        .mob_stat_empl {
            cursor: pointer;
        }
    }
    
    .mat-dialog-container {
        padding: 0 !important;
        background: #F1F1F1 !important;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1) !important;
        border-radius: 15px 15px 0px 0px !important;

        .mobile_interface_title {

            .mobile_interface_title_in {
                padding: 19px 25px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #E6E6E6;
                position: relative;

                .icon_back {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 18px;
    
                    svg {
                        fill: #232323;
                        width: 24px;
                        height: 24px;
                    }
                }
    
    
                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #383838;
                }
    
                .small_title {
                    position: absolute;
                    bottom: 6px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #000000;
                }
            }


        }

        .mobile_interface_content {
            & > .mat-tab-group {
                & > .mat-tab-body-wrapper {

                    & > .mat-tab-body {
                        // padding: 18px 32px;
                        // max-height: 60vh;
                        // overflow-y: auto;
                        // overflow-x: hidden;
                    }
                }
            }

            .mob_req {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                text-align: center;
                color: #c0392b;
            }

            .items {
                .item {
                    display: flex;
                    align-items: center;
                    background: #FFFFFF;
                    border-radius: 18px;
                    border: 1px solid transparent;
                    height: 32px;
                    padding: 0 16px;
                    max-width: 200px;

                    &.item_has_img {
                        padding-left: 0;

                        app-user {
                            width: 100% !important;
                        }

                        .user_div {
                            img {
                                width: 32px;
                                height: 32px;
                                margin-right: 9px;
                            }

                            mat-icon {
                                width: 32px;
                                height: 32px;
                                font-size: 32px;
                                line-height: 32px;
                                background: rgb(102, 102, 223);
                                border-radius: 100%;
                                margin-right: 9px;
                            }

                            span {
                                font-family: 'Roboto Condensed', sans-serif !important;
                                font-weight: 400 !important;
                                font-size: 18px !important;
                                line-height: 25px !important;
                                color: #000000 !important;
                                overflow: hidden !important;
                                text-overflow: ellipsis !important;
                                white-space: nowrap !important;
                            }
                        }
                    }

                    span {
                        font-family: 'Roboto Condensed', sans-serif;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 25px;
                        color: #000000;
                    }

                    &.active {
                        background: #EAE7E7;
                        border: 1px solid #686868;
                    }

                    &.first {
                        span {
                            color: #2F80ED !important; 
                        }
                        &.active {
                            span {
                                color: #2F80ED !important; 
                            }
                            background: #BCD7FD !important;
                            border-color: #2F80ED !important;
                        }
                      }
                      &.second { 
                        span {
                            color: #BEC11B !important;
                        }
                        &.active {
                            span {
                                color: #BEC11B !important;
                            }
                            background: #FEFFB9 !important;
                            border-color: #BEC11B !important;
                        }
                      }
                      &.third {
                        span {
                            color: #F56718 !important;
                        }
                        &.active {
                            span {
                                color: #F56718 !important;
                            }
                            background: #FFD0A7 !important;
                            border-color: #F56718 !important;
                        }
                      }
                      &.fours {
                        span {
                            color: #9B51E0 !important;
                        }
                        &.active {
                            span {
                                color: #9B51E0 !important;
                            }
                            background: #E4C8FF !important;
                            border-color: #9B51E0 !important;
                        }
                      }
                      &.fives {
                        span {
                            color: #DB0000 !important;
                        }
                        &.active {
                            span {
                                color: #DB0000 !important;
                            }
                            background: #FFAEAE !important;
                            border-color: #DB0000 !important;
                        }
                      }
                      &.sixs {
                        span {
                            color: #647C64 !important;
                        }
                        &.active {
                            span {
                                color: #647C64 !important;
                            }
                            background: #dce0dcfd !important;
                            border-color: #647C64 !important;
                        }
                      }
                      &.seven {
                        span {
                            color: #219653 !important;
                        }
                        &.active {
                            span {
                                color: #219653 !important;
                            }
                            background: #98F1BD !important;
                            border-color: #219653 !important;
                        }
                      }
                      &.nine {
                        span {
                            color: #686869 !important;
                        }
                        &.active {
                            span {
                                color: #686869 !important;
                            }
                            background: #E0E0E1 !important;
                            border-color: #686869 !important;
                        }
                      }
                      
                      &.eight {
                        span {
                            color: #686868 !important;
                        }
                        &.active {
                            span {
                                color: #686868 !important;
                            }
                            background: #E0E0E0 !important;
                            border-color: #686868 !important;
                        }
                      }

                    & + .item {
                        margin-top: 28px;
                    }
                }
            }

            .mob_interface_chips_out {
                .small_chips {
                    padding: 0 !important;

                    .mat-chip-list-wrapper {
                        flex-direction: column !important;
                        flex-wrap: wrap !important;
                        align-items: flex-start !important;
                        margin: 0 !important;

                        .mat-chip {
                            margin: 0;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 25px;
                            color: #000000;
                            padding: 3.5px 17px;
                            border-radius: 17px;
                            border: 1px solid transparent;
                            background: #D7D7D7;

                            &.mat-chip-selected {
                                background: #F3F3F3 !important;
                                border: 1px solid #A3A3A3 !important;
                            }

                            &::after { 
                                display: none !important;
                            }

                            & + .mat-chip {
                                margin-top: 28px;
                            }
                        }
                    }
                }   
            }

            .img_white_wrp {
                display: flex;
                align-items: center;
                background: rgba(255, 255, 255, 0.81);
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                padding-right: 25px;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 75px;
                    height: 75px;
                    margin-right: 10px;
                }
                
                & > div {
                    width: calc(100% - 100px);
                }
            }

            .link_title {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #686868;
                padding: 14px 32px;
                position: relative;

                &::after {
                    position: absolute;
                    content: '';
                    right: 0;
                    bottom: 0;
                    left: 32px;
                    height: 1px;
                    background: #E6E6E6;
                }
            }

            .date_and_time_c {
                position: relative;

                .white_title {
                    position: absolute;
                    top: 24px;
                    font-size: 11px;
                    font-weight: 400;
                    color: #686868;
                }

                & > .d-f-c {
                    justify-content: space-between;
                }
        
                svg {
                    fill: #2F80ED;
                }

                .mat-form-field {
                    .mat-form-field-infix {
                        width: 100px;
                    }

                    .mat-input-element {
                        font-size: 14px;
                        line-height: 30px;
                        font-weight: 400;
                    }
                }
        
                .ngx-timepicker-field-example {
                    position: relative;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 0;
                    padding-bottom: 6px;
                    transform: translateY(5px);

                    .ngx-timepicker__time-colon {
                        margin-left: 3px !important;
                    }
        
                    &::after {
                        transition: all 600ms cubic-bezier(0.25, 0.8, 0.25, 1);
                        position: absolute;
                        content: "";
                        height: 1px;
                        background: rgba(0,0,0,.42);
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
        
                    &:hover {
                        &::after {
                            height: 2px;
                        }
                    }

                    .ngx-timepicker-control {
                        width: 35px !important;
                    }

                    .ngx-timepicker-control__input {
                        padding: 0 !important;
                    }


                    
                    .ngx-timepicker {
                        border-bottom: none !important;
        
                        .ngx-material-timepicker-toggle {
                            span {
                                width: 24px;
                                height: 24px;
                            }
                        }
        
                        .ngx-timepicker-control__arrows {
                            display: none;
                        }
                        .ngx-timepicker-control__input {
                            font-size: 14px;
                            line-height: 30px;
                            background: transparent !important;
                        }
                    }
                }
            }

            .outlet_filter {
                height: 53px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 14px;
                border-bottom: 1px solid #E6E6E6;

            }

            .mob_accept_img {
                background: rgba(255, 255, 255, 0.81);
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                overflow: hidden;
                display: flex;
                position: relative;

                .edit_icon {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }

                img {
                    object-fit: cover;
                    width: 81px;
                    height: 81px;
                }
                .mob_accept_info {
                    padding: 13px 12px 10px 16px;
                    width: calc(100% - 109px);

                    .info_name {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 25px;
                        color: #000000;
                    }

                    .info_date {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 19px;
                            color: #686868;

                            &.apm {
                                font-weight: 400;
                                color: #000;
                            }
                        }
                    }
                }
            }

            .mobile_select {
                .mat-form-field-wrapper {
                    margin: 0 !important;
                    padding-bottom: 0 !important
                }
    
                .mat-form-field-infix {
                    padding: .5em 0 !important;
                    border-top: .2em solid transparent !important;
                    width: 86px;
                }
    
                .mat-select-arrow-wrapper {
                  transform: translateY(0) !important;
                }
    
                .mat-select-value-text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #686868;
                }
                .mat-form-field-outline {
                    background: #FFFFFF !important;
                    color: transparent !important;
                    border-radius: 16px !important;
                }

                &.mobile_input {
                    .mat-input-element {
                        border-bottom: 1px solid #E6E6E6;
                    }
                    .mat-form-field-infix {
                        width: 62px;
                    }
                }
            }

            .has_pads {
                padding: 18px 25px;

                .req_label {
                    width: 100%;
                }
            }

            .white_wrp {
                padding: 10px 22px;
                background: rgba(255, 255, 255, 0.81);
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
                border-radius: 6px;

                &.transp {
                    background: transparent;
                    box-shadow: none;
                    border-radius: 0;
                }
            }

            .mob_items {
                min-height: 30vh;
                max-height: 50vh;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 14px 0;

                .mob_item {
                    display: flex;
                    align-items: center;
                    height: 54px;
                    padding: 0 32px;

                    &.active {
                        span {
                            color: #1877F2;
                        }
                    }

                    .mob_item_icon_wrp {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        margin-right: 15px;

                        span {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 25px;
                            color: #000000;
                        }
                        
                        svg {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }

            .channel_trigger {
                position: relative;
                height: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #FFFFFF;
                border-radius: 16px;
                padding-right: 15px;

                & > .d-f-c {
                    width: calc(100% - 20px);
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                    }

                    svg {
                        width: 32px;
                        height: 32px;
                    }

                    & + span {
                        padding-left: 9px !important;
                    }
                }


                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 25px;
                    color: #383838;
                    max-width: calc(100% - 50px);
                    padding: 4px 9px 4px 15px;

                    &.small {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #686868;
                    }
                }
            }

        }
        .mobile_interface_buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 15px 25px 37px 25px;

            .mobile_interface_btn {
                outline: none;
                border: none;
                display: flex;
                align-items: center;
                background: transparent;
                border-radius: 4px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                padding: 8px 22px;
                color: #686868;

                &.mobile_interface_btn_white {
                    background: #FFFFFF;
                    box-shadow: 0px 1px 4px #A3A3A3;
                }
                
                &.mobile_interface_btn_blue {
                    background: #2F80ED;
                    box-shadow: 0px 1px 4px #A3A3A3;
                    color: #FFFFFF;
                }
            }
        }
    }
}
.exec_filter_trigger {
    overflow: hidden;
}
.timepicker-overlay,
.timepicker-backdrop-overlay {
    z-index: 10000 !important;
}
.mib-24 {
    font-size: 24px !important;
    line-height: 24px !important;
    width: 24px !important;
    height: 24px !important;

    .mat-button-wrapper {
        width: 24px !important;
        height: 24px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    mat-icon {
        font-size: 20px !important;
        line-height: 20px !important;
        width: 20px !important;
        height: 20px !important; 
    }
}
.mr-25-per {
    margin-right: 25%;
}
.mr-15-per {
    margin-right: 15%;
}
.ml-25-per {
    margin-left: 25%;
}
// .pepper {
//     background: #F8EFBA;
// }

.edit_chat_modal {
    min-width: 50vw;
    .mat-dialog-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.activity_modal {
    max-width: unset !important;

    .mat-dialog-container {
        padding: 0 !important;
        background: #ededed;
    }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
.rotateble {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
.close_stats_for {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #647C64;
    margin-left: 24px;
  
    svg {
      width: 8px;
      height: 8px;
    }
  }
  
.apply_empls_stat {
    height: 21px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #1877F2;
    border-radius: 3px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 24px;
}
.projects_dialog {
    max-width: 100vw !important;
}
.small_info_right_btn {
    margin: 0;
    color: #686868;
    font-size: 12px;
    line-height: 12px;
    margin-left: 20px;

    p {
        margin: 0;
    }
}
.loadTasks_progress {
    position: absolute !important;
    left: 20px;
    bottom: 4px;
    right: 20px;
    z-index: 100000000000000020000;
    width: calc(100% - 40px) !important;

    .mat-progress-bar-fill::after {
        background: #2F80ED !important;
    }
    .mat-progress-bar-buffer {
        background-color: #BCD7FD !important;
    }
}
.mat_proggress_w8 {
    position: absolute !important;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999999999999999999;

    &.job_proggress {
        z-index: 2 !important;
        bottom: -4px !important;
    }

    .mat-progress-bar-fill::after {
        background: #2F80ED !important;
    }
    .mat-progress-bar-buffer {
        background-color: #BCD7FD !important;
    }
}
.fixed_proggress {
    position: fixed !important;
}
@media (min-width: 1000px) {
    .create_task_by_manager_modal {
        max-width: 900px !important;
        min-width: 855px !important;
    }
}


.platform_img {
    width: 24px;
    height: 24px;
    &.platform_img_24 {

    }
    &.platform_img_invert {
        filter: invert(1);
    }
    &.platform_img_r {
        border-radius: 100%;
    }

}

.back_not_panel {
    max-width: unset !important;
    
    .mat-dialog-container {
        padding: 0 !important;
    }
}
.create_task_by_manager_modal {
    .mat-dialog-container {
        padding: 0 !important;
        background: #E5E5E5;
    }
    .dialog_title {
        background: #647C64;
        padding: 9px 40px;
        min-height: 40px;
        justify-content: flex-start;

        .mat-chip.mat-standard-chip {
            background: #EDEDED !important;
        }

        & > {
            span {
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                color: #FFFFFF;
            }
        }

        .users_select {
            margin-left: 33px;
            background: rgba(255, 255, 255, 0.51);
            border-radius: 4px;
            padding: 8px 15px;

            & > span {
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                color: #000000;
            }
        }
    }

    .white_block {
        margin: 20px 40px !important;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 22px 32px;
        width: calc(100% - 64px - 80px) !important;

        &:first-of-type {
            margin-top: 30px !important;
        }

        &.no_m_l_r {
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: calc(100% - 64px) !important;
        }

        .white_block_title {
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            padding-bottom: 15px;
        }

        .white_inside {
            visibility: hidden;
            height: 0;
            position: absolute;

            &:not(.open) {
                overflow: hidden;
            }
            
            &.open {
                position: unset !important;
                height: auto;
                padding-top: 10px;
                visibility: visible;
            }
        }

        &.white_expanded {
            .white_block_title {
                padding-bottom: 0 !important;
            }
            .d-f-c {
                cursor: pointer;
                justify-content: space-between;
            }
        }
    }
}

.statement_dialog {
    width: 975px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    
    .mat-dialog-container {
        padding: 40px !important;
    }
}
.SOME_CLASS {
    transform: translate(-16px, 30px) !important;
}

.SOME_HEAD_CLASS {
    transform: translate(-40px, 40px) !important;

    .mat-select-panel {
        max-height: 40vh;
    }
}

.SOME_LONG_CLASS {
    .mat-select-panel {
        max-height: 30vh;
    }
}
.job_e {
    width: 30px;
    height: 30px;
    position: relative;

    .calendar {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;

        svg {
          width: 12.7px;
          height: 14px;

          &.red {
            path {
              fill: #FF0000;
            }
          }
        }
      }
}
.without_paddings_modal {
    .mat-dialog-container {
        padding: 0 !important;
    }
}
.add_emp {
    position: relative;

    .calendar {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;

        svg {
          width: 10.7px;
          height: 12px;

          &.red {
            path {
              fill: #FF0000;
            }
          }
        }
      }
}

.mat-chip-list {
    &:not(.without_ordered):not(.scen_list) {

        .mat-chip-list-wrapper {
            .mat-chip {
                &:not(.clear) {
                    order: 3;
                }
        
                &:nth-child(0):nth-child(-n+8):not(.clear) {
                    order: 2;
                }
        
                &:nth-child(n+8) {
                    &:not(.more) {
                        display: none;
                    }
                }
            }
            .mat-chip-selected {
                &:nth-child(n+8) {
                    display: inline-flex !important;
                    order: 1 !important;
                }
            }
        }
    }
}
.sto_width {
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
}
.opacity-6 {
    opacity: .6;
}


.SOME_MENU_CST_CLASS {
    transform: translate(-16px, 42px) !important;
}
.full_height {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}
.show_header {
    // -webkit-box-shadow: 0 0px 10px 10px #d0d0d0 !important;
    // box-shadow: 0 0px 10px 10px #d0d0d0 !important;
    border-radius: 4px;

    .job_chat,
    .global_chat {
        height: calc(100vh - 61px) !important;
    }
}
.hide-form-field {
    overflow: hidden;
    height: 1px;
    visibility: hidden;
    padding: 0;
    margin: 0;
    
    * {
        padding: 0;
        margin: 0;
        height: 1px;
    }
}
.custom-scroll {
    scrollbar-width: thin;
    transition: scrollbar-color 0.3s ease;
    -webkit-overflow-scrolling: touch;
    // Fix scroll lock on iOS
    pointer-events: auto;
  
    &.custom-scroll-t {
      scrollbar-color: transparent transparent;
    }

    &.custom-scroll-h {
      scrollbar-width: none;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 0.375rem;
      // `box-shadow` prevents repaint on macOS when hovering out of scrollable container
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.01);
    }
  
    &:hover,
    &:focus,
    &:focus-within {
      scrollbar-color: var(--color-scrollbar) transparent;
  
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-scrollbar);
      }
    }
  }
.global_chats_dialog {
    .mat-dialog-container {
        padding: 0 !important;
    }
}

canvas,
[draggable=true],
.file_manager_dnd {
    -khtml-user-drag: element;
    -webkit-user-drag: element;
    // -khtml-user-select: none;
    // -webkit-user-select: none;
  }
.chats_modal {
    max-width: 95% !important;
    width: 95%;
    .mat-dialog-container {
        padding: 0 !important;
    }
}
.desk_statuses {
    max-width: 60% !important;
    width: 60%;
    .mat-dialog-container {
        padding: 0 !important;
    }
}

.platform_wrp {
    display: flex;
    align-items: center;

    div {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        margin-right: 10px;

 
    }
}
.no_p_stat {
    background: linear-gradient(45deg, rgba(196, 3, 61, 0.3), rgba(47, 138, 223, 0.2)) !important;
}
.cur_suffix {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #686868;
    padding-left: 5px;
}
.req_icon {
    position: absolute;
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    mat-icon {
        font-size: 14px;
        line-height: 14px;
        width: 14px;
        height: 14px;
        color: #c0392b;
    }
}
.req_field {
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    border: 1px solid #c0392b;
    border-radius: 3px;
    pointer-events: none;



    &.small {
        left: -1px;
        top: -1px;
        right: 1px;
        bottom: -1px; 
    }
}
.req_label {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background: #686868;
    padding: 5px 15px;

    &.small {
        padding: 3px 6px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 100%;

        .angle {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #686868;
        }

        span {
            font-weight: 500 !important;
            font-size: 10px !important;
            line-height: 10px !important;
        }
    }
    .angle {
        position: absolute;
        bottom: -5px;
        left: 10px;
        width: 0; 
        height: 0; 
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 11px solid #686868;
    }
    span {
        color: #fff !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 19px !important;
    }
}
.small_chips {
    position: relative;
    padding-bottom: calc(1.34375em - 10px);
    padding-top: 0.75em;

    & + .small_chips {
        margin-top: 15px;
    }

    .mat-chip-list-wrapper {
        padding-top: 18px;
    }
    & > label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #686868;
       
        
        span {
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
        }
    }


    .mat-chip {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #686868;
        background: #F3F3F3;
        border-radius: 16px;
        padding: 2px 12px;
        height: auto;
        min-height: unset;
        border: 1px solid transparent;
        cursor: pointer;

        &.first {
            color: #2F80ED !important; 
            &.mat-chip-selected {
                color: #2F80ED !important; 
                background: #BCD7FD !important;
                border-color: #2F80ED !important;
            }
          }
          &.second { 
            color: #BEC11B !important;
            &.mat-chip-selected {
                color: #BEC11B !important;
                background: #FEFFB9 !important;
                border-color: #BEC11B !important;
            }
          }
          &.third {
            color: #F56718 !important;
            &.mat-chip-selected {
                color: #F56718 !important;
                background: #FFD0A7 !important;
                border-color: #F56718 !important;
            }
          }
          &.fours {
            color: #9B51E0 !important;
            &.mat-chip-selected {
                color: #9B51E0 !important;
                background: #E4C8FF !important;
                border-color: #9B51E0 !important;
            }
          }
          &.fives {
            color: #DB0000 !important;
            &.mat-chip-selected {
                color: #DB0000 !important;
                background: #FFAEAE !important;
                border-color: #DB0000 !important;
            }
          }
          &.sixs {
            color: #647C64 !important;
            &.mat-chip-selected {
                color: #647C64 !important;
                background: #dce0dcfd !important;
                border-color: #647C64 !important;
            }
          }
          &.seven {
            color: #219653 !important;
            &.mat-chip-selected {
                color: #219653 !important;
                background: #98F1BD !important;
                border-color: #219653 !important;
            }
          }
          &.nine {
            color: #686869 !important;
            &.mat-chip-selected {
                color: #686869 !important;
                background: #E0E0E1 !important;
                border-color: #686869 !important;
            }
          }
          
          &.eight {
            color: #686868 !important;
            &.mat-chip-selected {
                color: #686868 !important;
                background: #E0E0E0 !important;
                border-color: #686868 !important;
            }
          }

        // & + .mat-chip {
        //     margin-left: 13px;
        // }
        &.mat-chip-selected {
            background: #F3F3F3 !important;
            color: #686868 !important;
            border: 1px solid #A3A3A3;
            box-sizing: border-box;
            font-weight: 400;
        }

        &.more {
            background: transparent;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #2F80ED;

            &:hover {
                background: transparent;

                ::after {
                    background: transparent;
                }
            }
        }
    }
}

.scen_list {
    .mat-chip-list-wrapper {
        .mat-chip {
            &:not(.clear) {
                order: 3;
            }
    
            &:nth-child(0):nth-child(-n+40):not(.clear) {
                order: 2;
            }
    
            &:nth-child(n+40) {
                &:not(.more) {
                    display: none;
                }
            }
        }
        .mat-chip-selected {
            display: inline-flex !important;
            order: 1 !important;
        }
    }
}
.scen_list {

    .mat-chip-list-wrapper {
        padding-top: 18px;
    }
    & > label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #686868;
       
        
        span {
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
        }
    }


    .mat-chip {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        border-radius: 16px;
        padding: 2px 12px;
        height: auto;
        min-height: unset;
        cursor: pointer;

        &.more {
            background: transparent;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #2F80ED;

            &:hover {
                background: transparent;

                ::after {
                    background: transparent;
                }
            }
        }
    }
}
.search_type {
    .mat-button-toggle-group {
        box-shadow: none;

        .mat-button-toggle  {
            border: 1px solid #ccc;
            border-radius: 18px;
            overflow: hidden;
            line-height: 24px;
            margin: 2.5px;

            .mat-button-toggle-label-content {
                line-height: 24px;
            }

            &.mat-button-toggle-checked {
                background: transparent;
                color: #000;
                border: 1px solid #000;
            }
        }
    }
}
.priority_chips {
    padding-bottom: calc(1.34375em - 10px);
    padding-top: 0.75em;

    .mat-chip-list-wrapper {
        padding-top: 18px;
    }

    & > label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #686868;
        
        span {
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
        }
    }

    .mat-chip {
        background: #fff;
        position: relative;
        border-radius: 0;
        cursor: pointer;
        box-shadow: 2px 2px 2px -1px rgba(0, 0, 0, 0.25);

        & + .mat-chip {
            margin-left: 21px;
        }

        &::before {
            content: "";
            position: absolute;
            width: 5px;
            left: 0;
            top: 0;
            bottom: 0;
            display: block;
        }
        &.Urgent {
            &::before {
                background: #DB0000;
            }
            &.mat-chip-selected {
                background: #DB00005E !important;
                color: #DB0000 !important;
            }
        }
        &.High {
            &::before {
                background: #F56718;
            }
            &.mat-chip-selected {
                background: #F567185E !important;
                color: #F56718 !important;
            }
        }
        &.Middle {
            &::before {
                background: #219653;
            }
            &.mat-chip-selected {
                background: #2196535E !important;
                color: #219653 !important;
            }
        }
        &.Low {
            &::before {
                background: #2F80ED;
            }
            &.mat-chip-selected {
                background: #2F80ED5E !important;
                color: #2F80ED !important;
            }
        }
        &.No {
            &::before {
                background: #D6D6D6;
            }
            &.mat-chip-selected {
                background: #D6D6D65E !important;
                color: #686868 !important;
            }
        }
    }
}
body {
    background: #e5e5e5;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-text-size-adjust: none;
    font-size: 15px;
    font-weight: 300;
}
.ml-50 {
    margin-left: 50px;
}
.mr-50 {
    margin-right: 50px;
}
.cases_ids_wrp {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Roboto Condensed', sans-serif;
    .case_ids {
        text-align: right;
        margin-right: 5px;
        .case_c_id {
            font-weight: 300;
            font-size: 10px;
            line-height: 10px;
            color: #333;
        }
        .case_id {
            color: #C9C9C9;
            font-size: 10px;
            line-height: 10px;
        }
    }

    span {
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: #686868;
    }

    &.small_ids_wrp {
        span {
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
        }

        .case_ids {

            .case_c_id {
                font-size: 8px;
                line-height: 8px;
            }
            .case_id {
                font-size: 8px;
                line-height: 8px;
            }
        }
    }
}

// .req_value {
//     background: #ff00000a;
//     padding: 15px;
//     position: relative;
//     width: calc(100% - 30px) !important;

//     &::before {
//         content: "*";
//         font-weight: 300;
//         color: #e74c3c;
//         font-size: 32px;
//         left: 3px;
//         top: 3px;
//         position: absolute;
//     }
// }
.file_manager_modal {
    height: 100vh !important;
    width: 100vw !important;
    max-width: unset !important;

    .mat-dialog-container {
        padding: 0 !important;
    }
}
.radio_more_btn {
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 2px 12px;
    font-weight: 400;
    background: #fff;
    cursor: pointer;
}
.with_tabs_scen {
    padding: 0 !important;
    cursor: default;

    .tabs_scen {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            cursor: pointer;
            padding: 2.5px 7.5px;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            border-bottom: 1px solid transparent;
            -webkit-transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
            -o-transition: all .3s cubic-bezier(.43,.195,.02,1) !important;
            transition: all .3s cubic-bezier(.43,.195,.02,1) !important;

            &.active {
                color: #1877F2;
                border-color: #1877F2;
            }
        }
    }
}

.users_select {
    display: flex;
    flex-direction: column;
    padding-bottom: calc(1.34375em - 10px);
    padding-top: 0.75em;
    position: relative;
    
    label {
        font-weight: 500;
        padding-bottom: 0.7em;
    }

    .chip_user {
        cursor: pointer !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        min-height: 24px !important;
        font-weight: 400 !important;
    
        &.more {
            padding-left: 12px !important;
        }
    }
}
.bigSize {
    .mat-badge-content {
        width: auto;
        min-width: 32px;
        border-radius: .5rem;
        right: -18px !important; 
    }
}
.file-manager-modal {
    .mat-dialog-container {
        padding: 0 !important;
    }
}

// FILTER



.filter_dialog {
    .mat-dialog-container {
        overflow-x: hidden;
    }
}

// .mat-select-panel-wrap {
//     transform: translateY(30px);
// }
// .hide-form-field {
//     transform: translateY(-30px);
// }
.fm_interface {
    .filter_out {    
        margin-top: 5px;
        background: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        
        
        .filter_in {
          padding: 0 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
    
          .filter_form_in {
            display: flex;
            align-items: center;
          }
      
          .filter_btn {
            margin: 0 12px;
            background: #1877F2;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            padding: 4px 12px;
            border: none;
            outline: none;
            font-weight: bold;
            font-size: 10px;
            line-height: 10px;
            color: #EDEDED;
            cursor: pointer;
      
         
            &.reset_filter_btn {
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
              color: #686868;
              background: #fff;
              margin-left: 15px;
            }
            
          }
      
          // .example-form-field {
          //   font-size: 14px;
          //   color: #686868;
          //   padding-left: 5px;
          //   padding-right: 5px;
          //   height: 38px;
      
          //   &.date {
          //     max-width: 179px;
        
          //     .mat-form-field-prefix .mat-icon {
          //       width: 20px;
          //       height: 20px;
          //       font-size: 20px;
          //       line-height: 1;
          //       color: #686868;
          //     } 
          //   }
          // }
      
      
          .filter_form {
            font-size: 14px;
            color: #686868;
            padding-left: 5px;
            padding-right: 5px;
            height: 38px;
            display: flex;
            align-items: center;
      
            .mat-form-field-label {
              font-weight: normal;
              font-size: 14px;
              line-height: 14px;
              color: #686868;
            }
      
            &.search {
              max-width: 165px;
              .mat-form-field-prefix .mat-icon {
                width: 20px;
                height: 20px;
                font-size: 20px;
                line-height: 1;
                color: #2F80ED;
              } 
              .mat-form-field-suffix .mat-icon {
                width: 16px;
                height: 16px;
                font-size: 16px;
                line-height: 1;
                color: #A3A3A3;
              }
            }
      
            &.views_status {
                max-width: 100px;
            }
            &.type {
              max-width: 66px;
            }
            &.priority {
              max-width: 100px;
            }
            &.group {
              max-width: 81px;
            }
            &.executor {
              max-width: 88px;
            }
            &.job {
              max-width: 75px;
            }
            &.status {
              max-width: 77px;
            }
            &.profile {
              max-width: 79px;
            }
            &.publication {
              max-width: 108px;
            }
            &.url {
              max-width: 68px;
            }
      
            &.date {
              max-width: 179px;
              border-right: 1px solid #EDEDED;
              .mat-form-field-prefix {
                width: 30px;
                height: 30px;
                transform: translate(-1px, -1px);
                display: flex;
                align-items: center;
                justify-content: center;
      
                svg {
                  width: 20px;
                  height: 20px;
                  font-size: 20px;
                  line-height: 1;
                  color: #686868;
                } 
              }
      
              &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
                transform: translateY(-10px) scale(0.75) perspective(100px) translateZ(0.001px);
                -ms-transform: translateY(-10px) scale(0.75)
              }
            }
        
      
      
            &:not(.date) {
              &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
                transform: none !important;
                -ms-transform: none !important;
                width: unset !important;
              }
            }
      
            &:not(.search) {
              border-left: 1px solid #EDEDED;
              .mat-form-field-underline {
                display: none !important;
              }
      
              .mat-select-arrow {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid;
              }
              .mat-select-value-text {
                display: none;
              }
            }
      
            .mat-form-field-wrapper {
              width: 100%;
              padding-bottom: 0 !important;
              // margin: 0 !important;
      
              .mat-form-field-flex {
                align-items: center;
              }
            }
            .mat-form-field-infix {
              border-top: 0 !important;
            }
            .mat-form-field-underline {
              bottom: 0 !important;
            }
          }
        }
    
    }
    .filter_values {
      border-top: 1px solid #EDEDED;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .chips_wrp {

        .mat-chip-list-wrapper {
          display: inline-flex;
          background: #F3F3F3;
          border: 1px solid #C9C9C9;
          border-radius: 17px;

          .mat-chip {
            background: #647C64;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: #EDEDED;
            height: 20px;
            min-height: 20px;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 4px;

            &.user_chip {

              &.is_real {
                padding-left: 0;
              }
              .user_div {
                img {
                  width: 20px;
                  height: 20px;
                }
                mat-icon {
                  width: 20px;
                  height: 20px;
                  font-size: 20px;
                  line-height: 20px;
                }
              }
            }

            .remove_chip_btn {
              color: #EDEDED;
              width: 12px;
              height: 12px;
              font-size: 12px;
              opacity: 1;
            }

            &::after {
              background: transparent !important;
            }
          }
        }
      }

      .remove_all_chips_btn {
        width: 16px;
        height: 16px;
        line-height: 16px; 
        color: #647C64;
        font-size: 16px;
        margin-right: 4px;
        cursor: pointer;
      }
    }
}
.sort_tablet  {
    max-width: 400px !important;

    &.new_sort_int {

        button {
            padding-left: 16px !important;
        
            &:hover {
              background: transparent !important;
              color: #1877F2 !important;
            }
        }
        .active_status {
            background: transparent !important;
            color: #1877F2;
        
            mat-icon {
                color: #686868;
                &.active {
                    color: #1877F2;
                }
            }
        }
    }

    .mat-menu-title {
        height: 32px;
        padding: 0 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .d-f-c {

        .select_wrp {
            width: calc(100% - 185px);
            height: 24px !important;
            display: flex;
            align-items: center;
            padding-right: 10px;

            & > mat-icon {
                & + mat-icon {
                    margin-left: 1.5px !important;
                }
            }

            .select_in {
                position: relative;
                display: flex;
                align-items: center;
                white-space: nowrap;
                width: calc(100% - 75px);
            }
        }
        mat-icon {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 24px;
            margin: 0 !important;
            border-radius: 100%;

            &:hover,
            &.active {
                color: #1877F2 !important;
            }
        }
    }

  .mat-menu-content {
    padding: 0 !important;
  }
  .mat-menu-item .mat-icon {
    color: #686868;
  }
  button {
    line-height: 32px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #686868;

    &.flex_start {
        justify-content: flex-start;
        flex-shrink: 0;
        width: 185px !important;
    }
    
    mat-icon {
      margin-left: 10px;
      color: #686868;
    }

    &:hover {
      background: transparent !important;
      color: #1877F2;
    }
  }
  .active_status {
    color: #1877F2AD;
  }
}
.bookmarks_settings,
.cases_page {
    .task_collections_wrp {
      // height: 24px;
      padding: 4px 10px;
      background: #FFFFFF;
      display: flex;
      align-items: center;

      & > span{
            
        & + span {
            margin-left: 3px;
        }
        &:first-of-type {
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
            color: #FF0000;
        }
    
        &:nth-child(2),
        &:nth-child(3) {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #FF0000;
    
        }
      }
  
  
      button {
        &:not(.no_cst_style) {
            border: none;
            outline: none;
            margin-left: 8px;
            background: #FF0000;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #EDEDED;
            padding: 3px 7px;
            cursor: pointer;
        }
      }
    }
  
    .select_all_checkbox {
  
      &.mat-checkbox-checked {
        .mat-ripple-element {
          opacity: 0.03 !important;
          background-color: #FF0000!important;
        }
        .mat-checkbox-background {
          background-color: #FF0000 !important;
        }
      }
  
      // color: #FF0000;
      // .mat-checkbox-background {
      //     background-color: #FF0000;
      // }
  
      // &.mat-checkbox-focused{
      //     .mat-ink-ripple{
      //         background-color: #ff00002c;
      //     }
      // }
    }
  

  
    .lists_wrp  {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 1px solid #C9C9C9;
      padding-top: 5px;
  
      .lists_left {
        display: flex;
        align-items: flex-end;
      }
  
      .check_wrp {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        
        .mat-checkbox-ripple {      
          height: 32px;
          width: 32px;
          left: calc(50% - 16px);
          top: calc(50% - 16px);
          border-radius: 100%;
        }
      }
  
      .job_status_wrp {
        display: flex;
        align-items: center;
  
        button {
          border-radius: 0;
          -webkit-transition: border .3s cubic-bezier(.43,.195,.02,1) !important;
          -o-transition: border .3s cubic-bezier(.43,.195,.02,1) !important;
          transition: border .3s cubic-bezier(.43,.195,.02,1) !important;
          border-bottom: 2px solid transparent;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          color: #686868;
          position: relative;
          padding: 0 8px;
          min-width: 40px;
          font-family: 'Roboto Condensed', sans-serif;
  
          &:hover {
            &::after {
              height: 2px;
              border-radius: 1px;
              background: #2F80ED;
              content: "";
              position: absolute;
              bottom: -2px;
              left: 0;
              right: 0;
            }
          }
        }
  
        .active_status {
          color: #2F80ED;
          &::after {
            height: 2px;
            border-radius: 1px;
            background: #2F80ED;
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
          }
        }
      }
    }
  
    .stats_wrp {
  
      .tasks_counters {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border-radius: 3px;
        align-self: center;
  
  
  
        .counter_block {
  
          .counter_top {
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
            color: #686868;
          }
  
          .counter_bottom {
            font-weight: 500;
            font-size: 12px;
            line-height: 13px;
            color: #2F80ED;
            
            .currency_bottom {
              font-weight: 400;
              color: #6299E1;
            }
  
            span {
              & + span {
                margin-left: 20px;
              }
            }
          }
  
          & + .counter_block {
            margin-left: 20px;
          }
        }
  
        .empl_info {
          position: relative;
          margin-left: 7px;
          padding-left: 7px;
          display: flex;
          align-items: center;
          border-left: 1px solid #EDEDED;
  
          .percent {
            font-style: normal;
            font-weight: 200;
            font-size: 35px;
            line-height: 25px;
            color: #2F80ED;
            padding-right: 7px;
          }
  
          &:hover {
            .hover_stats {
              visibility: visible;
            }
          }
  
          .hover_stats {
            visibility: hidden;
            position: absolute;
            left: calc(100% + 16px);
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            color: #686868;
            white-space: nowrap;
          }
        }
      }
    }
  
  
    .filter_out {    
      margin-top: 5px;
      background: #fff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      
      
      .filter_in {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .filter_form_in {
          display: flex;
          align-items: center;
        }
    
        .filter_btn {
          margin: 0 12px;
          background: #1877F2;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          padding: 4px 12px;
          border: none;
          outline: none;
          font-weight: bold;
          font-size: 10px;
          line-height: 10px;
          color: #EDEDED;
          cursor: pointer;
    
       
          &.reset_filter_btn {
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            color: #686868;
            background: #fff;
            margin-left: 15px;
          }
          
        }
    
        // .example-form-field {
        //   font-size: 14px;
        //   color: #686868;
        //   padding-left: 5px;
        //   padding-right: 5px;
        //   height: 38px;
    
        //   &.date {
        //     max-width: 179px;
      
        //     .mat-form-field-prefix .mat-icon {
        //       width: 20px;
        //       height: 20px;
        //       font-size: 20px;
        //       line-height: 1;
        //       color: #686868;
        //     } 
        //   }
        // }
    
    
        .filter_form {
          font-size: 14px;
          color: #686868;
          padding-left: 5px;
          padding-right: 5px;
          height: 38px;
          display: flex;
          align-items: center;
    
          .mat-form-field-label {
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #686868;
          }
    
          &.search {
            max-width: 165px;
            .mat-form-field-prefix .mat-icon {
              width: 20px;
              height: 20px;
              font-size: 20px;
              line-height: 1;
              color: #2F80ED;
            } 
            .mat-form-field-suffix .mat-icon {
              width: 16px;
              height: 16px;
              font-size: 16px;
              line-height: 1;
              color: #A3A3A3;
            }
          }
    
        &.views_status {
            max-width: 100px;
        }
          &.type {
            max-width: 66px;
          }
          &.priority {
            max-width: 100px;
          }
          &.group {
            max-width: 81px;
          }
          &.executor {
            max-width: 88px;
          }
          &.job {
            max-width: 75px;
          }
          &.status {
            max-width: 77px;
          }
          &.profile {
            max-width: 79px;
          }
          &.publication {
            max-width: 108px;
          }
          &.url {
            max-width: 68px;
          }
    
          &.date {
            max-width: 179px;
            border-right: 1px solid #EDEDED;
            .mat-form-field-prefix {
              width: 30px;
              height: 30px;
              transform: translate(-1px, -1px);
              display: flex;
              align-items: center;
              justify-content: center;
    
              svg {
                width: 20px;
                height: 20px;
                font-size: 20px;
                line-height: 1;
                color: #686868;
              } 
            }
    
            &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
              transform: translateY(-10px) scale(0.75) perspective(100px) translateZ(0.001px);
              -ms-transform: translateY(-10px) scale(0.75)
            }
          }
      
    
    
          &:not(.date) {
            &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
              transform: none !important;
              -ms-transform: none !important;
              width: unset !important;
            }
          }
    
          &:not(.search) {
            border-left: 1px solid #EDEDED;
            .mat-form-field-underline {
              display: none !important;
            }
    
            .mat-select-arrow {
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 5px solid;
            }
            .mat-select-value-text {
              display: none;
            }
          }
    
          .mat-form-field-wrapper {
            width: 100%;
            padding-bottom: 0 !important;
            // margin: 0 !important;
    
            .mat-form-field-flex {
              align-items: center;
            }
          }
          .mat-form-field-infix {
            border-top: 0 !important;
          }
          .mat-form-field-underline {
            bottom: 0 !important;
          }
        }
      }
  
      .filter_values {
        border-top: 1px solid #EDEDED;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
  
        .chips_wrp {
  
          .mat-chip-list-wrapper {
            display: inline-flex;
            background: #F3F3F3;
            border: 1px solid #C9C9C9;
            border-radius: 17px;
  
            .mat-chip {
              background: #647C64;
              font-weight: normal;
              font-size: 12px;
              line-height: 12px;
              color: #EDEDED;
              height: 20px;
              min-height: 20px;
              padding-top: 0;
              padding-bottom: 0;
              padding-right: 4px;
  
              &.user_chip {
  
                &.is_real {
                  padding-left: 0;
                }
                .user_div {
                  img {
                    width: 20px;
                    height: 20px;
                  }
                  mat-icon {
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    line-height: 20px;
                  }
                }
              }
  
              .remove_chip_btn {
                color: #EDEDED;
                width: 12px;
                height: 12px;
                font-size: 12px;
                opacity: 1;
              }
  
              &::after {
                background: transparent !important;
              }
            }
          }
        }
  
        .remove_all_chips_btn {
          width: 16px;
          height: 16px;
          line-height: 16px; 
          color: #647C64;
          font-size: 16px;
          margin-right: 4px;
          cursor: pointer;
        }
      }
    }
  }





// FILTER
// app-file-manager {
//     width: 80vw;
//     height: 80vh;
//     display: block;
//     // display: none;

//     // &.full {
//     //     position: fixed;
//     //     z-index: 99
//     // }

//     // &.open {
//     //     display: block;
//     // }
// }
// html.cdk-global-scrollblock {
//     position: initial !important;
//   }
  
// html.cdk-global-scrollblock body {
//     position: fixed;
// }
.blured_background {
    background-color: #00000042;
    backdrop-filter: blur(4px) !important;
}
.mat-checkbox-checkmark {
    width: inherit !important;
    height: inherit !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.mr-1 {
    margin-right: 10px !important;
}
.mr-15 {
    margin-right: 15px !important;
}
.ml-1 {
    margin-left: 10px !important;
}
.ml-15 {
    margin-left: 15px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mb-25 {
    margin-bottom: 25px !important;
}
.underline {
    text-decoration: underline;
}
.d-f-c {
    display: flex !important;
    align-items: center !important;
}
.d-f {
    display: flex !important;
}
svg {
    width: 24px;
    height: 24px;
}
html, body { min-height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.container {
    width: 100%;
    margin: 0 auto;
}
.d-f-btn {

    .mat-button-wrapper {
        display: flex;
        align-items: center;
    }
}
.spin_24 {
    width: 24px !important;
    height: 24px !important;

    svg {
        width: 24px !important;
        height: 24px !important; 
    }
}
.statuses_load {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: -18px;
    z-index: 2;
    // pointer-events: none;
}
.spin_16 {
    width: 14px !important;
    height: 14px !important;

    svg {
        width: 14px !important;
        height: 14px !important; 
    }
}
.spin_64 {
    width: 64px !important;
    height: 64px !important;

    svg {
        width: 64px !important;
        height: 64px !important; 
    }
}
.spin_50 {
    width: 50px !important;
    height: 50px !important;

    svg {
        width: 50px !important;
        height: 50px !important; 
    }
}
.spin_12 {
    width: 10px !important;
    height: 10px !important;

    svg {
        width: 10px !important;
        height: 10px !important; 
    }
}

.wizard_dialog {
    .mat-dialog-container {
        padding: 12px 40px !important;
    }
}
.mat-dialog-container {
    padding: 12px 24px !important;
}

@media(max-width: 769px) {
    .back_not_panel {
        max-width: unset !important;
        
        .mat-dialog-container {
            max-height: calc(var(--vh, 1vh)*95) !important;

            .mobile_scrollable_int_big {
                height: calc(var(--vh, 1vh)*80);
                max-height: calc(var(--vh, 1vh)*80);
                padding: 6px !important;
            }
        }
    }
    
    .full_width_dialog {
        max-width: 100% !important;
        width: 100%;
    }
    
    .speech_file_pane {
        max-width: 100% !important;
    }
    .bottom-sheet {
        flex-direction: column;
        align-items: unset !important;
        
        .profiles_confirm {
            .d-f-c {
                flex-wrap: wrap;
            }
        }
        
        button {
            margin-top: 5px;
        }
    }

    .filter_dialog {
        max-width: 95vw !important;
        width: 95vw;

        .mat-dialog-container {
            max-height: 90vh !important;
            overflow-x: hidden;
        }
    }
    .ml-50 {
        margin-left: 10px;
    }
    .mr-50 {
        margin-right: 10px;
    }
    .mat-dialog-container {
        padding: 12px !important;
    }
    .video_view_modal {
        max-width: unset !important;
        width: 100%;
        .mat-dialog-container {
            max-width: 100vw !important;
            height: 100%;
            width: 100%;
            mat-dialog-content {
                max-height: unset !important;
    
                .preview_wrp {
                    width: 100%;
                }
            }
        }
    }
}
@media(min-width: 768px) {
    // .show_header {
    //     &.file_manager_modal {
    //         padding-top: 56px;
    //     }
    // }
    .backdrop_under_header {
        top: 58px;
        background: rgba(0,0,0,.32);

        &:not(.without_pad) {
            & + .cdk-global-overlay-wrapper {
                padding-top: 29px;
            }
        }
    }
    .file_backdrop_under_header {
        & + .cdk-global-overlay-wrapper {
            padding-top: 55px !important;
        }
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 760px;
    }

    .filter_dialog,
    .video_viewer,
    .panel_user_calendar_schedule,
    .open_task_dialog {
       .container:not(.alert_wrp) {
           min-width: 760px;
       }
    }
    .open_task_dialog {
        .mat-dialog-container {
            overflow-x: hidden;   
        }
    }
}
@media(min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 980px;
    }
    .filter_dialog,
    .video_viewer,
    .panel_user_calendar_schedule,
    .open_task_dialog {
        .container:not(.alert_wrp) {
            min-width: 980px;

            .connections {
                margin: 25px 162px;
            }
            .comments {
                margin: 25px 162px;
            }
        }
     }
}
@media(min-width: 1250px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1240px;
    }
    .filter_dialog,
    .video_viewer,
    .panel_user_calendar_schedule,
    .open_task_dialog {
        & > .mat-dialog-content {
            & > .container:not(.alert_wrp) {
                min-width: 1240px;
    
                .connections {
                    margin: 25px 168px;
                }
                .comments {
                    margin: 25px 168px;
                }
            }
        }

     }
}
@media(min-width: 1500px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1400px;
    }
    .filter_dialog,
    .panel_user_calendar_schedule,
    .video_viewer,
    .open_task_dialog {
        .container:not(.alert_wrp) {
            min-width: 1400px;
        }
     }
}
.chat_last_post {
    max-height: 24px;

    .text_one_line {
        max-height: 19px;
        line-height: 19px !important;
    }
}
.modal_has_contenteditable {
    max-width: 866px !important; 
}
.d-f-c.user_in {
    app-user {
        width: 100%;
    }
}
.upload_ask {
    max-width: 580px !important; 
    width: 100% !important; 
}
.text_one_line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}
.text_2_line {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.text_3_line {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.text_4_line {
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.text_5_line {
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.table_container {
    width: 100%;
    margin: 0 auto;
}
.submited_btn {
    background: #b4f49054 !important;
}

// @media(min-width: 768px) {
//     .table_container, .table_container-lg, .table_container-md, .table_container-sm, .table_container-xl {
//         max-width: 760px;
//     }
// }
// @media(min-width: 992px) {
//     .table_container, .table_container-lg, .table_container-md, .table_container-sm, .table_container-xl {
//         max-width: 984px;
//     }
// }
// @media(min-width: 1250px) {
//     .table_container, .table_container-lg, .table_container-md, .table_container-sm, .table_container-xl {
//         max-width: 1242px;
//     }
// }
// @media(min-width: 1550px) {
//     .table_container, .table_container-lg, .table_container-md, .table_container-sm, .table_container-xl {
//         max-width: 1542px;
//     }
// }
// @media(min-width: 1750px) {
//     .table_container, .table_container-lg, .table_container-md, .table_container-sm, .table_container-xl {
//         max-width: 1742px;
//     }
// }

.mat-dialog-container {
    .form_wrapper {
        max-height: calc(100vh - 190px) !important;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.content_link_info {
    padding: 5px;
    margin: 10px 0;
    background: #e6e6e675;
    border-radius: .25rem;
}
.mat-tooltip {
    border: 1px solid #ccc;
    border-radius: .25rem;
    background: #fff;
    color: #212529 !important;
    border-radius: 4px;
    margin: 14px;
    max-width: auto;
    width: auto;
    padding: .5rem .75rem;
    font-size: 13px;
    line-height: 15px;
    overflow: hidden;

    * {
        white-space: nowrap;
    }
}
.centred_page {
    min-height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
}
.full_width_form {
    width: 100%;
}
.mat-mini-fab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_btn {
    display: flex;
    color: #000;
    align-items: center;
    text-decoration: none;

    mat-icon {
        margin-right: 10px;
    }
}

.btns_line {
    padding: 10px 0;
}

.tasks_table {
    td,th {
        padding: 0 5px !important;
    }
}
.table_page_wrp {
    width: 100%;
    table {
        .mat-column-Link {
            max-width: 200px;
            min-width: 200px;
            width: 200px;
            overflow: hidden;
        }
        .mat-column-Date {
            font-size: 11px;
            max-width: 35px;
            min-width: 35px;
            width: 35px;
        }

        .mat-column-Private-Comment,
        .mat-column-Employee-comment,
        .mat-column-Comment {
            min-width: 200px;
            width: 200px;
            max-width: 200px;
            overflow: hidden;
        }
        
        td.cdk-column-Platform {
            .platform_icon {
                span {
                    max-width: calc(100% - 23px);
                }
            }
        }

        .mat-column-Expand,
        .cdk-column-Actions {
            text-align: center;
        }
        &.global_table {
            & > tbody {
                & > tr {
                    &:nth-child(2n+1) {
                        background: rgb(248, 248, 248);

                        .sub_table {
                            background: rgb(248, 248, 248);
                        }
                    }
                    &.checklist_alert {
                        background: #fef4f7 !important;
        
                        .expanded_sub_table {
                            background: #fef4f7 !important;
                        }
                    }
                    &.highlight_task {
                        background: #ade0b8 !important;
                        
                        .sub_table {
                            background: #ade0b8 !important;
                        }
                    }
                }

                tr {


                    &:not(:first-of-type) {
                        .sub_table {
                            .mat-header-row {
                                display: none !important;
                            }
                        }
                    }
                    .sub_table {
                        th,td {
                            min-width: 100px;
                            width: 100px;
                        }

                        .mat-column-Private-Comment,
                        .mat-column-Employee-comment,
                        .mat-column-Comment {
                            min-width: 200px;
                            width: 200px;
                            max-width: 200px;
                            overflow: hidden;
                        }
                        
                        .mat-column-Date {
                            font-size: 11px;
                            max-width: 60px;
                            min-width: 60px;
                            width: 60px;
                        }
                        .mat-column-Actions {
                            max-width: 40px;
                            min-width: 40px;
                            width: 40px;
                        }

                        .mat-column-Rate {
                            max-width: 65px;
                            min-width: 65px;
                            width: 65px;
                        }
                    }
                }
            }

            &.vertical_table {
                tbody {
                    .platform_icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        & > * {
                            &:first-of-type{
                                 margin-right: 10px;
                            }
                        }
                    }

                    .veritcal_sub_table {
                        &:first-of-type {
                            margin-top: 5px;
                        }
                        &:last-of-type {
                            margin-bottom: 5px;
                        }
                        & + .veritcal_sub_table {
                            margin-top: 5px;
                        }
                    }

                    // .example-expanded-row {
                    //     & > td {
                    //         &:first-of-type {
                    //             border-left: 1px solid #3f51b5 !important;
                    //         }
                    //         &:not(:first-of-type):not(:last-of-type) {
                    //             border-top: 1px solid #3f51b5 !important;
                    //         }
                    //         &:last-of-type {
                    //             border-right: 1px solid #3f51b5 !important;
                    //         }
                    //     }

                    //     & + tr {
                    //         border-left: 1px solid #3f51b5 !important;
                    //         border-right: 1px solid #3f51b5 !important;
                    //         border-bottom: 1px solid #3f51b5 !important;
                    //     }
                    // }

                    .mat-column-Actions {
                        width: 24px !important;
                    }

                    .mat-column-Expand {
                        width: 24px;
                        max-width: 24px;
                        min-width: 24px;
                    }
                    tr {
                        &.highlight_task {
                            background: #ade0b8 !important;

                            table {
                                background: #ade0b8 !important;
    
                                tr {
                                    background: #ade0b8 !important;
                                }
    
                            }
                        }
                        &.checklist_alert {
                            background: #fef4f7 !important;
            
                            .expanded_sub_table {
                                background: #fef4f7 !important;
                            }
                        }
                        &:nth-child(2n) {
                            & > td {
                                &.expanded_td {
                                    padding: 0 !important;
                                }
                            }
                        }

                        &:nth-child(4n+1),
                        &:nth-child(4n+2) {
                            background: rgb(248, 248, 248) !important;
                            table {
                                background: rgb(248, 248, 248) !important;
    
                                tr {
                                    background: rgb(248, 248, 248) !important;
                                }
    
                            }
                        }
                        &:nth-child(4n+3),
                        &:nth-child(4n+4) {
                            background: #fff !important;
                            table {
                                background: #fff !important;
    
                                tr {
                                    background: #fff !important;
                                }
                            }
                        }
                    }
                }
            }

        }
        .mat-row:hover .mat-cell {
            background: #ecf3eb;
        }
        width: 100%;

        .company_btns {
            max-width: 24px;
            min-width: 24px;
            width: 24px;
        }

        .table_name {
            min-width: 150px;
        }
        .th-centred {
            text-align: center;
        }
        th,
        td {
            &:not(.no_padding) {
                padding: 0 5px;
                // white-space: nowrap;
            }

            &:not(:first-of-type):not(.no_border) {
                border-left: 1px solid #e0e0e0;
            }

            &.no_padding {
                vertical-align: top;

                .sub_table {

                }
            }
        }



        .sub_table {
            .mat-row {
                &:last-of-type {
                    th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
                        border-bottom: none;
                    }
                }
            }
        }

        .mat-table-sticky-border-elem-left {
            border-right: 1px solid #e0e0e0;
          }
    }
}

.chat_snippet {
    height: 77px !important;
    background: #F3F3F3;
    position: relative;

    .indicators {
        position: absolute;
        left: 0;
        top: 9px;
        bottom: 6px;
        padding: 3px 0 4px 0;
        width: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
  
        .no_read_point {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: #FF0000;
          opacity: 0;
  
          &.show {
            opacity: 1;
          }
        }
  
        .pinned_chat {
          color: #A3A3A3;
          font-size: 12px;
          width: 12px;
          height: 12px;
          opacity: 0;
          &.show {
            opacity: 1;
          }
        }
      }
    
    &:not(.no_read) {
        .chat_snippet_in .snippet_footer .msg .action {
            background: #fff;
        }
    }

    & + .chat_snippet {
        border-top: 1px solid #E5E5E5;
    }

    &:hover {
        background: #FDFFF0 !important;
    }

    &.no_read {
        background: #fff;
    }
    &.mat-list-single-selected-option, &.mat-list-single-selected-option:focus {
       background: #1877F2 !important;
       
        .action {
            background: #a5cafa !important;
        }


       & * {
          &:not(mat-icon):not(.action):not(.action b) {
              color: #fff !important;
          } 
       }
    }
    .mat-list-item-content {
        padding: 0 !important;
        align-items: flex-start !important;
    }
    .mat-list-text {
        padding: 9px 6px 5px 24px !important;
    }
    .chat_snippet_in {

        .snippet_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 3px;

            .name {
                font-family: 'Roboto Condensed', sans-serif;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #686868;
            }
            .date {
                margin-left: 20px;
                white-space: nowrap;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #647C64;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
        .snippet_body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 25px;

            .snippet_info {
                display: flex;
                align-items: center;

                .member {
                    height: 20px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 18px;
                        height: 18px;
                        border-radius: 100%;
                        border: 1px solid #fff;
                    }
                    mat-icon {
                        font-size: 18px;
                        width: 18px;
                        height: 18px;
                        border-radius: 100%;
                        background: #a1beea;
                        border: 1px solid #fff;
                    }

                    span {
                        margin-left: 3px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #000000;
                    }
                }
            }

            .chat_employees {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                  .block_inside {
                      display: flex;
                      align-items: center;
  
                      .chat_empl {
                        width: 19px;
                        height: 19px;
                        position: relative;
  
                        .calendar {
                          width: 12px;
                          height: 12px;
                          border-radius: 100%;
                          background: #fff;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: absolute;
                          bottom: -6px;
                          left: 50%;
                          transform: translateX(-50%);
                          z-index: 9;
  
                          svg {
                            width: 8.7px;
                            height: 10px;
  
                            &.red {
                              path {
                                fill: #FF0000;
                              }
                            }
                          }
                        }
                      }
                      
                      img {
                        border-radius: 100%;
                        border: 1px solid #fff;
                        width: 17px;
                        height: 17px;
                      }
                      mat-icon {
                          background: #a1beea;
                          width: 17px;
                          height: 17px;
                          font-size: 17px;
                          border-radius: 100%;
                          border: 1px solid #fff;
                      }
  
                      .more_empl {
                          color: #686868;
                          font-weight: 400;
                          font-size: 10px;
                      }
                  }
              }
        }
        .snippet_footer {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            &.cen {
                align-items: center;
            }

            .chat_last_post {
                max-width: 100%;
                display: flex;
                align-items: center;
            }
            .last_post_file {
                margin-left: 23px;
                display: flex;
                align-items: center;
                // padding: 3px;
                // border-radius: 3px;
                // background: #fff;
            
                .file_view {
                  display: flex;
                  align-items: center;
                  width: 18px !important;
                  height: 18px !important;
                  margin: 0 !important;
            
                  & > * {
                    width: 18px !important;
                    height: 18px !important;
                  }
            
                  img,
                  mat-icon {
                    width: 18px !important;
                    height: 18px !important;
                    border-radius: 0 !important;
                    line-height: 18px !important;
                  }
                  & * {
                    margin: 0 !important;
                  }
                }
            
                .file_info {
                  display: flex;
                  flex-direction: column;
            
                  .file_name {
                    font-size: 15px;
                    line-height: 15px;
                    margin: 0;
                    font-weight: 400;
                  }
                  .file_size {
                    margin: 0;
                    font-size: 9px;
                    line-height: 9px;
                  }
                }

                & + .msg {
                    margin-left: 5px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    max-width: 235px;
                }
              }

            .msg {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #686868;
                max-width: 282px;
                
                &.text_msg {
                    max-width: 282px;
                }

                .action {
                    background: #F3F3F3;
                    border-radius: 12px;
                    margin-left: 17px;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 12px;
                    color: #686868;
                    display: inline-flex;
                    align-items: center;
                    padding: 2px 3px 2px 6px;

                    b {
                        font-weight: 500%;
                    }
                    
                    img {
                        width: 14px;
                        height: 14px;
                        border-radius: 100%;
                        border: 1px solid #fff;
                    }
                    mat-icon {
                        font-size: 14px;
                        width: 14px;
                        height: 14px;
                        border-radius: 100%;
                        background: #a1beea;
                        border: 1px solid #fff;
                    }
                }
            }

            .who_read {
                margin-left: 3px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 14px;
                opacity: .8;

                div {
                    height: 14px;
                    width: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        border-radius: 100%;
                        border: 1px solid #fff;
                        width: 12px;
                        height: 12px;
                      }
                      mat-icon {
                          background: #a1beea;
                          width: 12px;
                          height: 12px;
                          font-size: 12px;
                          border-radius: 100%;
                          border: 1px solid #fff;
                      }
                }
            }
        }
    }
}
.paddings_block {
    padding-left: 10px;
    padding-right: 10px;
}

.user_div {
    display: flex;
    align-items: center;
    
    img {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin-right: 5px;
    }
    mat-icon {
        margin-right: 5px !important;
    }
    span {
        font-weight: 400;
        white-space: pre-wrap;
    }
}

.alert_wrp {

    .mat-column-Date {
        white-space: nowrap;
    }

    .user_div {
        padding: 0 5px;

        span {
            white-space: nowrap;
        }
    }

}
.task_ids_wrp_out {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .task_ids_wrp {
        width: 70%;
    }

    .group {
        text-align: right;
        width: 25%;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #647C64;
    }
}
.task_ids_wrp {
    display: flex;
    
    .task_ids {
        margin-left: 10px;
        position: relative;
        span {
            line-height: 8px;
            font-size: 8px;
            position: absolute;
            right: 0;
            bottom: 5px;
            transform: translateX(110%);
        }
    }
}
.custom_radio {
    display: flex;
    flex-direction: column;
    padding-bottom: calc(1.34375em - 10px);
    padding-top: .75em;

    & > label {
        font-weight: 500;
        padding-bottom: .7em;
    }
    mat-radio-button {
        margin-bottom: 10px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 0;
        padding-right: 12px;

        &.mat-radio-checked {
            border-color: #2F80ED;
        }

        &:not(:last-of-type) {
            margin-right: 10px;
        }

        .mat-radio-ripple {
            left: calc(50% - 15px);
            top: calc(50% - 15px);
            height: 30px;
            width: 30px;
            border-radius: 100%;
        }

        &.first {
            background: #BCD7FD !important;
            color: #2F80ED;

            .mat-radio-outer-circle {
              border-color: #2F80ED !important;
            }
            .mat-radio-inner-circle {
               background-color: #2F80ED !important; 
            }
            &.mat-radio-checked {
                border-color: #2F80ED;
            }
        }
          &.second { 
            background: #FEFFB9 !important;
            color: #BEC11B;

            .mat-radio-outer-circle {
                border-color: #BEC11B !important;
            }
            .mat-radio-inner-circle {
                background-color: #BEC11B !important; 
            }
            &.mat-radio-checked {
                border-color: #BEC11B;
            }
          }
          &.third {
            background: #FFD0A7 !important;
            color: #F56718;

            .mat-radio-outer-circle {
                border-color: #F56718 !important;
            }
            .mat-radio-inner-circle {
                background-color: #F56718 !important; 
            }
            &.mat-radio-checked {
                border-color: #F56718;
            }
          }
          &.fours {
            background: #E4C8FF !important;
            color: #9B51E0;

            .mat-radio-outer-circle {
                border-color: #9B51E0 !important;
            }
            .mat-radio-inner-circle {
                background-color: #9B51E0 !important; 
            }
            &.mat-radio-checked {
                border-color: #9B51E0;
            }
          }
          &.fives {
            background: #FFAEAE !important;
            color: #DB0000;

            .mat-radio-outer-circle {
                border-color: #DB0000 !important;
            }
            .mat-radio-inner-circle {
                background-color: #DB0000 !important; 
            }
            &.mat-radio-checked {
                border-color: #DB0000;
            }
          }
          &.sixs {
            background: #dce0dcfd !important;
            color: #647C64;

            .mat-radio-outer-circle {
                border-color: #647C64 !important;
            }
            .mat-radio-inner-circle {
                background-color: #647C64 !important; 
            }
            &.mat-radio-checked {
                border-color: #647C64;
            }
          }
          &.seven {
            background: #98F1BD !important;
            color: #219653;

            .mat-radio-outer-circle {
                border-color: #219653 !important;
            }
            .mat-radio-inner-circle {
                background-color: #219653 !important; 
            }
            &.mat-radio-checked {
                border-color: #219653;
            }
          }
          &.nine {
            background: #E0E0E1 !important;
            color: #686869;

            .mat-radio-outer-circle {
                border-color: #686869 !important;
            }
            .mat-radio-inner-circle {
                background-color: #686869 !important; 
            }
            &.mat-radio-checked {
                border-color: #686869;
            }
          }
          
          &.eight {
            background: #E0E0E0 !important;
            color: #686868;

            .mat-radio-outer-circle {
                border-color: #686868 !important;
            }
            .mat-radio-inner-circle {
                background-color: #686868 !important; 
            }
            &.mat-radio-checked {
                border-color: #686868;
            }
          }
    }
}
.table_wrp {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    max-height: calc(100vh - 179.35px);
    
    .main_table {
        width: 100%;
        
        td {
            &.mat-column-Expand,
            &.cdk-column-Actions {
                text-align: center;
            }

            .td_with_icon {
                display: flex;
                align-items: center;
                
                mat-icon {
                    margin-right: 5px;
                }
            }
        }

    }
}

.mat-menu-panel.create_menu {
    max-height: 35vh;
    min-width: 150px;
}

.tasks_table {
    .without_paddings {
        padding: 0 !important;
    }
}
.without_paddings {
    padding: 0 !important;
}
.mat-column-Work {
    width: 574px !important;
    max-width: 574px !important;
    min-width: 574px !important;

    &.with_checkbox {
        width: 614px !important;
        max-width: 614px !important;
        min-width: 614px !important;
    }
}
.mat-column-Profile {
    width: 525px !important;
    max-width: 525px !important;
    min-width: 525px !important;
}
td.profile_preview {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
}
.expanded_table {

    & > tbody {
        & > tr {
            &:hover {
                .alert_btn {
                    opacity: 1;
                }
            }
        }
    }
    .mat-column-Profile {
        vertical-align: top;
    }
    .platform_icon {
        display: flex;
        align-items: center;

        svg,
        mat-icon {
            margin-right: 5px;
        }
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .profile_t_header {

        &.show {
            div {
                &:first-of-type {
                    display: flex;
                    align-items: center;
                    height: 56px;
                    justify-content: center;
                    border-bottom: none;
                }
                &:last-of-type {
                    display: none;
                }
            }
        }
        div {
            &:first-of-type {
                display: flex;
                align-items: center;
                height: 27px;
                justify-content: center;
                border-bottom: 1px solid #e0e0e0;
            }
            &:last-of-type {
                display: flex;
                align-items: center;

                span {
                    height: 28px;
                    display: flex;
                    align-items: center;
                    padding: 0 5px;

                    &:nth-child(1) {
                        width: 80px;
                        max-width: 80px;
                        min-width: 80px;
                    }
                    &:nth-child(2) {
                        width: 60px;
                        max-width: 60px;
                        min-width: 60px;
                    }
                    &:nth-child(3) {
                        width: 110px;
                        max-width: 110px;
                        min-width: 110px;
                    }
                    &:nth-child(4) {
                        width: 50px;
                        max-width: 50px;
                        min-width: 50px;
                    }
                    &:nth-child(5) {
                        width: 120px;
                        max-width: 120px;
                        min-width: 120px;
                    }
                    // &:nth-child(4) {
                    //     width: 150px;
                    //     max-width: 150px;
                    //     min-width: 150px;
                    // }
                    &:nth-child(6) {
                        width: 40px;
                        max-width: 40px;
                        min-width: 40px;
                    }

                    & + span {
                        border-left: 1px solid #e0e0e0;
                    }
                }
            }
        }
    }
    .t_header {

        &.show {
            div {
                &:first-of-type {
                    display: flex;
                    align-items: center;
                    height: 56px;
                    justify-content: center;
                    border-bottom: none;
                }
                &:last-of-type {
                    display: none !important;
                }
            }
        }
        div {
            &:first-of-type {
                display: flex;
                align-items: center;
                height: 27px;
                justify-content: center;
                border-bottom: 1px solid #e0e0e0;
            }
            &:last-of-type {
                display: flex;
                align-items: center;

                &.with_checkbox {
                    span {
                        &:nth-child(2) {
                            width: 85px;
                            max-width: 85px;
                            min-width: 85px;
                        }
                        &:nth-child(3){
                            width: 60px;
                            max-width: 60px;
                            min-width: 60px;
                        }
                        &:nth-child(4) {
                            width: 110px;
                            max-width: 110px;
                            min-width: 110px;
                        }
                        &:nth-child(1) {
                            width: 30px;
                            max-width: 30px;
                            min-width: 30px;
                        }
                        &:nth-child(6) {
                            width: 40px;
                            max-width: 40px;
                            min-width: 40px;
                        }
                        &:nth-child(5) {
                            width: 225px;
                            max-width: 225px;
                            min-width: 225px;
                        }
    
                    }
                }

                span {
                    height: 28px;
                    display: flex;
                    align-items: center;
                    padding: 0 5px;

                    &:nth-child(1) {
                        width: 85px;
                        max-width: 85px;
                        min-width: 85px;
                    }
                    &:nth-child(2){
                        width: 60px;
                        max-width: 60px;
                        min-width: 60px;
                    }
                    &:nth-child(3) {
                        width: 110px;
                        max-width: 110px;
                        min-width: 110px;
                    }
                    &:nth-child(5) {
                        width: 40px;
                        max-width: 40px;
                        min-width: 40px;
                    }
                    &:nth-child(4) {
                        width: 225px;
                        max-width: 225px;
                        min-width: 225px;
                    }

                    & + span {
                        border-left: 1px solid #e0e0e0;
                    }
                }
            }
        }
    }

    .mat-column-Task,
    .mat-column-Employee-comment,
    .mat-column-Comment,
    .mat-column-Private-Comment {
        padding: 6px 5px !important;
    }

    .expanded_sub_table {
        width: auto !important;
        max-width: auto !important;
        min-width: auto !important;

        .mat-header-row {
            display: none !important;
        }
        .mat-column-Operation {
            width: 85px;
            max-width: 85px;
            min-width: 85px;
            font-size: 11px;
            position: relative;

            span {
                &.alert_btn {
                    left: -6.5px;
                    bottom: -6.5px;
                    right: unset;
                    opacity: 0;
                    position: absolute;
                    mat-icon {
                        color: rgba(0, 0, 0, 0.2) !important;
                        transform: scale(0.7);
                        cursor: pointer;
                    }
                }
            }
        }
        .mat-column-Date {
            font-size: 11px;
            width: 60px;
            max-width: 60px;
            min-width: 60px;
        }
        .mat-column-Completed {
            font-size: 11px;
            width: 60px;
            max-width: 60px;
            min-width: 60px;
        }
        .mat-column-Performed-by {
            width: 100px;
            max-width: 100px;
            min-width: 100px;
        }   

        .mat-column-Status {
            width: 110px;
            max-width: 110px;
            min-width: 110px;
        }
        .mat-column-Rate {
            width: 65px;
            max-width: 65px;
            min-width: 65px;
        }

        .mat-column-Employee-comment,
        .mat-column-Comment,
        .mat-column-Private-Comment {
            width: 225px;
            max-width: 225px;
            min-width: 225px;
        }





        .mat-column-Platform {
            width: 80px;
            max-width: 80px;
            min-width: 80px;
        }
        .mat-column-Content-Name {
            width: 120px;
            max-width: 120px;
            min-width: 120px;
            white-space: pre-wrap !important;
        }
        .mat-column-Actions {
            width: 40px;
            max-width: 40px;
            min-width: 40px;
        }
        .mat-column-Uploaded-By {
            width: 150px;
            max-width: 150px;
            min-width: 150px;
        }

    }
    & > tbody {
        & > tr {
            &:nth-child(2n+1) {
                background: rgb(248, 248, 248) !important;

                .expanded_sub_table {
                    background: rgb(248, 248, 248) !important;
                }
            }
            &.checklist_alert {
                background: #fef4f7 !important;

           
            }
            &.highlight_task {
                background: #ade0b8 !important;

                .expanded_sub_table {
                    background: #ade0b8 !important;
                }
            }
        }
    }


}
.mat-column-Task {
    span {
        width: 100%;
        white-space: pre-wrap;
    }
}
.mat-column-Id {
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
    text-align: right;
}
.mat-column-Id {
    .mat-checkbox {
        &:not(.mat-checkbox-checked) {
            opacity: 0;
            visibility: hidden;
        }
    }
}    
tr:hover {
    .mat-column-Operation {

        span {
            &.alert_btn {
                opacity: 1 !important;
            }
        }
    }
    .mat-column-Id {
        .mat-checkbox {
            opacity: 1;
            visibility: visible;
        } 
    }
}
.task_date_td,
.mat-column-Status,
td.mat-column-Id {
    position: relative;

    .no_check {
        position: absolute;
        right: 2px;
        bottom: 2px;
        font-size: 8px;
        line-height: 8px;

        &.sys_date {
            bottom: unset;
            right: unset;
            left: 2px;
            top: 2px;

            div {
                text-align: left;
            }
        }
        &.rate_and_price {
            
            div {
                color: green !important;
            }
        }

        &.alert_btn {
            left: -3px;
            bottom: -3px;
            right: unset;
            opacity: 0;

            mat-icon {
                color: rgba(0, 0, 0, 0.2) !important;
                transform: scale(.7);
                cursor: pointer;
            }

            &.connection_btn {
                bottom: unset;
                top: -3px;
            }
        }


        div {
            text-align: right;
            color: rgba(0, 0, 0, 0.3) !important;
        }
    

    }
}

.pos_rel {
    position: relative;
}
.pos_abs_hide {
    position: absolute;
    left: -3px;
    top: -5px;
    z-index: 2;
}
td.mat-column-Status {
    font-size: 12px;
}

.no-select-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none;      
    -ms-user-select: none;       
    user-select: none;
}

mat-icon {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none;      
    -ms-user-select: none;       
    user-select: none;
}

.dialog_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// .mat-column-Status {
//     width: 60px;
//     max-width: 60px;
//     min-width: 60px;
//     overflow: hidden;
// }

.mat-column-Status {
    &.task_status {
        width: 80px;
        max-width: 80px;
        min-width: 80px;
        overflow: hidden;

        span {
            color: rgba(0, 0, 0, 0.3) !important;
        }
    }
}

.mat-column-btns {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    text-align: center;
}

.alert-snackbar {
    background: transparent;
    color: #000 !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 100vh !important;
    min-height: 100vh !important;
    width: 100vw !important;
    max-width: 100vw !important;
    box-shadow: none !important;
    
    notifications-bar {
        .alert_backdrop {
            background: rgba(0, 0, 0, 0.25);
            height: 100vh !important;
            min-height: 100vh !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .alert_wrp {
        width: 80vw;
        max-width: 80vw;
        max-height: calc(100vh - 28px);
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        color: #000 !important;
        padding: 14px 4px;
        border-radius: 4px !important;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
}

.dateTooltip {
    font-size: 10px;
    padding: 4px;
    margin: 0;
    transform: translateY(12px) !important;
}
.mobileStatus  {
    transform: translateX(30px) !important;

    .active_status  {
        background: rgb(63, 81, 181) !important;
        color: #fff !important;
    }
}
.transparent_bg {
    background: transparent !important;
}

.back-mini-global-interface {
    display: none;

    & + .cdk-global-overlay-wrapper {
        z-index: 9999;
    }
}

@media(max-width: 769px) {
   .back-mini-global-interface {
    display: none;

    & + .cdk-global-overlay-wrapper {
        z-index: 1000 !important;
    }
} 
}
.bottom-sheed-backdrop {
    background: rgba($color: #000000, $alpha: .32);

    & + .cdk-global-overlay-wrapper {
        z-index: 99999;
    }
}
.cdk-overlay-connected-position-bounding-box {
    z-index: 10000;

    & ~ * {
        z-index: 10000;
    }
}
// .platforms_file {
//     .platform_consolid {
//         height: 18px !important;
//         margin: 2px !important;
//         border-radius: 9px !important;
//         background: #F5F5F5 !important;

//         .post_tab_count {
//             font-size: 12px !important;
//             padding: 0 !important;
//         }

//         app-platform {
//             svg,img {
//                 width: 18px !important;
//                 height: 18px !important;
//             }
//         }
//     }
// }
.job_scenarios_hover {
    position: absolute;
    z-index: 150;
    top: 100%;
    left: 0;
    padding-top: 5px;

    &.mob_out_scen {
        z-index: 150;
        left: -101px;
    }
    
    .job_scenarios_hover_in {
        border-radius: 4px;
        background: #fff;
        padding: 5px;
        width: 300px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    }
    


    .mat-chip-list-wrapper {
        label {
            width: 100%;
            padding: 3px;
            font-size: 11px;
            line-height: 11px;
        }

        .mat-chip {
            padding: 0 5px;
            align-items: center;
            font-size: 11px;
            line-height: 11px;
            min-height: 20px !important;

            .active {
                margin-right: 4px;
                width: 18px;
                height: 18px;
                line-height: 18px;
                font-size: 18px;
                color: #fff;
            }
        }
        .mat-standard-chip {
            margin: 2px;
        }

    }
}

.mobile_scrollable_int {
    height: calc(var(--vh, 1vh)*55);
    max-height: calc(var(--vh, 1vh)*55);
    overflow-x: hidden;
    overflow-y: auto;
}

.mobile_scrollable_int_big {
    height: calc(var(--vh, 1vh)*65);
    max-height: calc(var(--vh, 1vh)*65);
    overflow-x: hidden;
    overflow-y: auto;
}


.mobile_scrollable_int_tasks {
    height: calc(var(--vh, 1vh)*55 - 78px);
    max-height: calc(var(--vh, 1vh)*55 - 78px);
    overflow-x: hidden;
    overflow-y: auto;
}
.full_screen_dialog {
    width: 100vw;
    max-width: 100vw !important;
    // height: calc(var(--vh, 1vh)*100);
    height: 100%;

    .mat-dialog-container {
        height: 100%;
    //   height: 100vh;
      overflow: hidden;
      border-radius: 0 !important;
    } 
    .lists_window {
      width: 400px;
      height: 100%;
      border-right: 1px solid #ccc;
      overflow-x: hidden;
      overflow-y: auto;
      
      &.disable_scroll {
        overflow: hidden !important;
      }

      .chats_filter {
        position: sticky;
        top: 0;
        z-index: 1;
        
        .mat-button-toggle-group {
          justify-content: center;
          width: 100%;
          background: #ffffffed;

          .mat-button-toggle-checked {
            color: #000;
            font-weight: 500;
          }
        }
      }

      .chats_element_wrp {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .right_chat {
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 75px;

          .middle_line {
            padding: 0;
          }
          .img_line {

          }

          .date_span,
          .middle_line {
            width: 100%;
            display: block;
          }
        }
      }
      

      .highlight_chat {
        background-color: rgb(202, 226, 152) !important;
      }
    }
    .mat-selection-list {
      padding: 0 !important;
    }
    .chat_element {
      height: 88px !important;

      .mat-list-item-content {
        padding: 0 8px !important;
      }
      .mat-list-text {
        padding: 0 !important;

        .img_line {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .post_author {
            display: flex;
            align-items: center;
            span {
              font-weight: 600;
            }
  
          }
          .date_span {
            font-size: 11px;
            white-space: nowrap;
            line-height: 19px;
          }
        }

        .bottom_line {
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          img {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            margin-top: 2px;
            margin-right: 5px;
          }
          span {
            width: calc(100% - 29px);
            white-space: pre-wrap;
            font-weight: 400;
            max-height: 40px;
            overflow: hidden;
          }
        }

        .middle_line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          line-height: 12px;
          padding: 8px 0;

          .task_ids_wrp {
            display: inline-flex;
            margin-right: 15px;
            
            .task_ids  {
              margin-left: 0;
              color: rgba(0, 0, 0, 0.5);
              span {
                bottom: 1px;
                right: -28px;
                text-align: right;

                div {
                  display: inline-block;
                }
              }
            }
          }

          span {
            white-space: pre-wrap;

            &:first-of-type {
              font-weight: 500;
            }
          }
        }

      }
      & + .chat_element {
        border-top: 1px solid #ccc;
      }
    }
      
  }
  
.disable_scroll {
    overflow: hidden !important;
}
.full-height {
    height: calc(var(--vh, 1vh)*100);
}
// .auto_complete_input {
//     order: 100;
// }
@media(max-width: 769px) {
    .chat_window {
        app-chat {
            height: 100%;
            display: block;
        }
    }
    .table_container {
        .not_table_container {
            overflow: auto !important;
        }
    }
    .global_chats_dialog {
        max-width: 100vw !important;
        width: 100vw;
        padding: 0 !important;

        .dates {
            text-align: right;
        }

        .mat-dialog-container {
            // height: calc(var(--vh, 1vh)*100);
            // max-height: unset !important;
            height: 100% !important;
            max-height: 100% !important;

            .posts_wrp {
                width: 100% !important;
                // min-height: calc(100vh - 125pt - 96px);
                // max-height: calc(100vh - 125pt - 96px);
                // height: 100%;
            }
            
            .global_chat {
                // height: calc(var(--vh, 1vh)*100);
                height: 100%;

                #mob_menu_toggler {
                    z-index: 9;
                    position: absolute;
                    right: -38px;
                    top: -7px;
                    display: flex !important;

                    svg {
                        height: 46px !important;
                    }
                }
                &.selected {
                    .lists_window {
                        opacity: 0;
                        display: none;
                    }
                    .chat_window {
                        display: block;

                        .chat_wrp {
                            width: 100%;
                        }
                    }
                    
                }
                .lists_window {
                    width: 100%;
                    -webkit-transition: all .4s cubic-bezier(.43,.195,.02,1) !important;
                    -o-transition: all .4s cubic-bezier(.43,.195,.02,1) !important;
                    transition: all .4s cubic-bezier(.43,.195,.02,1) !important;
                    display: block;
                }
                .chat_window {
                    // position: absolute;
                    width: 100%;
                    -webkit-transition: all .4s cubic-bezier(.43,.195,.02,1) !important;
                    -o-transition: all .4s cubic-bezier(.43,.195,.02,1) !important;
                    transition: all .4s cubic-bezier(.43,.195,.02,1) !important;
                    display: none;
                }

                .lists_window {
                    height: calc(var(--vh, 1vh)*100);
                    width: 100%;
                }
    
                .chat_window {
                    height: calc(var(--vh, 1vh)*100);
                    width: 100% !important;
    

                }
            }
        }
    }

    .chats_dialog {
        max-width: 100vw !important;
        width: 100vw;

        .mat-dialog-container {
            padding: 8px !important;

            .chats_wrp {
                overflow: auto !important;
                width: calc(100vw - 16px);
            }
        }
    }
    .unselectable {
        *:not(input):not(textarea):not(select) {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none;   /* Chrome/Safari/Opera */
            -khtml-user-select: none;    /* Konqueror */
            -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* Internet Explorer/Edge */
            user-select: none;           /* Non-prefixed version, currently
                                            not supported by any browser */
        }
    }
    .mat-dialog-container {
        max-height: 80vh !important;
    }
    
    // липкий фильтр так не работает
    // html,body {
    //     overflow-x: hidden !important;
    // }
    .alert-snackbar {
       
        notifications-bar {
            .alert_backdrop {

            }
        }
    
        .alert_wrp {
            max-width: 100vw;
            height: calc(100vh - 28px);
            max-height: calc(100vh - 28px);

            .cdk-drag-handle {
                display: none;
            }
            .alert_title {
                & > mat-button {
                    min-width: 30px !important;
                    padding: 0 4px;
                }
            }
            .table_container {
                max-height: calc(100vh - 236px) !important;
            }
            td, tr, th {
                padding: 0 !important;
            }
            .mat-column-Date,
            .mat-column-Type {
                &:not(th) {
                    font-size: 11px;
                }
            }
        }
    }
}

.full-screen-modal {
    max-width: unset !important;
    width: 100%;
    height: 100%;
    .mat-dialog-container {
      max-width: 100vw !important;
      max-height: 100vh !important;
      height: 100%;
      width: 100%;
      padding: 0 !important;
      .mat-dialog-content {
        max-height: unset !important;
      }
    }
  }

  .p-l-r {
      padding: 0 10px;
  }

  .mat-column-Preview {
      padding: 5px !important;
  }
  .task_preview {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
.job-option {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .not_assigned {
        position: relative;
        z-index: 2;
        margin-left: 5px !important;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6px !important;
        border-radius: 3px;
        background: #F3F3F3; 
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 300; 

        &.active {
            background: #990101 !important;
            color: #fff !important;
        }
    }
}
.job-option-2 {

    .not_assigned {
        position: relative;
        z-index: 2;
        margin-left: 5px !important;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6px !important;
        border-radius: 3px;
        background: #F3F3F3; 
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 300; 

        &.active {
            background: #990101 !important;
            span {
                color: #fff !important;
            }
        }
    }
}
.privacy_status_icon {
    font-size: 14px !important;
    line-height: 14px !important;
    width: 14px !important;
    height: 14px !important;
}
.has_not_assigned  {
    .mat-list-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row !important;
        padding-right: 0 !important;

        .not_assigned {
            position: relative;
            z-index: 2;
            margin-left: 5px !important;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 6px !important;
            border-radius: 3px;
            background: #F3F3F3; 
            color: #000;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 300; 
    
            &.active {
                background: #990101 !important;
                color: #fff !important;
            }
        }
    }

}
// app-file-interface {
//     height: 0;
//     position: fixed;
//     left: 50%;
//     transform: translate(-50%, 0);
//     bottom: 0;
//     z-index: 9999;


    

// }

.icon_16 {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #333;
}
.upload_panel {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transition: all .6s cubic-bezier(.43,.195,.02,1);
    -o-transition: all .6s cubic-bezier(.43,.195,.02,1);
    transition: all .6s cubic-bezier(.43,.195,.02,1);
    pointer-events: none;

    .file_wrp {
        pointer-events: all;
    }

    &.is_expanded {
        transform: translate(-50%, calc(var(--filesHeight) - 52px));
    }
}
.red_chip {
    background: #99010185 !important;
}

//   for drag & drop chips

.chips-boundary {
    padding: 5px 0;
}
.cst_drag_placeholder {
    border: 1px dotted gray;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    width: calc(50% - 40px);
    margin: 15px 20px;
    height: 736px;
    padding: 0 !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}
.sorted_chips {  
    display: flex;
    .chips_sorter_container {
    //   display: grid;
    //   grid-gap: 1rem;
  
    //   grid-auto-rows: minmax(auto, auto);
    //   grid-template-columns: repeat(5, auto);

      display: flex;
      flex-wrap: wrap;
      gap: 4px;
  
      .chips_drag_placeholder {
        border-radius: 16px;
        border: 3px dotted gray;
        background-color: lightgray;
      }
  
      .sorted_chip.cdk-drag-dragging {
        width: 100px;
        overflow: hidden;
        cursor: move !important;
      }
      .sorted_chip.cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
      .sorted_chip {
        min-width: 100px;
        border-radius: 16px;
        flex: 1 1 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;

        .mat-chip {
            cursor: move !important;

            .mat-chip-remove {
                cursor: pointer !important;
            }
        }
      }
  
      .sorted_chip:active {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        opacity: 0.6;
      }
  
      .mat-chip {
        justify-content: space-between;
        margin: 0 !important;
      }
    }
  }
  
    .sorted_chip.cdk-drag-preview {
        // user-select: all !important;
        // touch-action: auto !important;
        cursor: move !important;
        
        .mat-chip {
            // pointer-events: auto !important;
            cursor: move !important;
        }
    }

  .chips_drag_placeholder {
    min-width: 100px;
  }
  .chips_drag_placeholder,
  .sorted_chips_drop_list {
    display: flex;
    flex-direction: column;
  }
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    cursor: move !important;
  }
  .cdk-drag-placeholder {
    opacity: 0.3;
    border-radius: 16px;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  
  body:has(.cdk-drag-preview) {
    cursor: move !important;
  }

  .h_cst_t {
    .mat-select-trigger {
        & > .mat-select-value {
            overflow: visible !important;
        }
    }
    .mat-select-arrow-wrapper {
        transform: none !important;
    }
  }
  .cst_o {
    .mat-option-text {
        overflow: visible !important;
    }
    .mat-list-text {
        overflow: visible !important;
    }
  }

  .flex_text {
    &.active {
        background: #EBEBEB !important; 
    }
    .mat-list-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row !important;
        padding-right: 0 !important;
    }
    .not_assigned {
        position: relative;
        z-index: 2;
        margin-left: 5px !important;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6px !important;
        border-radius: 3px;
        background: #F3F3F3; 
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 300; 
    }
  }
  

  .select-outlet-in-mob {
    .mat-dialog-container {
        max-height: 100% !important;
    }
  }

  .mob_edit_profile_int {
    .mat-dialog-container {
        max-height: 100% !important;
    }
  }

  .error_label {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    color: #c0392b;
    font-size: 14px;
    line-height: 16px;
  }

  .p-none {
    opacity: .5;
    pointer-events: none !important;
  }


  .char_counter_hint {
    &.red {
        color: #990101;
    }
  }

  .b_w {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

.no_label {
    .mat-form-field-label {
        &:not(.mat-form-field-empty) {
            display: none !important;
        }
    }
    .mat-form-field-infix {
        border-top: 0 !important;
    }
}
//   full_screen_modal video_editor

.full_screen_modal {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;

    .mat-dialog-container {
        padding: 0 !important;
    }
}

.fl {
    &::first-letter {
        text-transform: uppercase;
    }
}



.context-menu {
    z-index: 1000;
    padding: 0 16px;
    animation: fadeIn 0.1s ease-out;
    opacity:1.0;
    display:block;

    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 7px;

    &.with_search {
        max-height: 30vh;
        overflow-x: hidden;
        overflow-y: auto;

        .search_wrp {
            background: #fff;
            padding: 5px 0;
            position: sticky;
            top: 0;
            margin: 0 !important;

            input {
                padding: 0 4px;
                border: 1px solid #ccc;
                padding: 2px 4px;
            }
        }
    }
  }
  
  
  .context-menu hr {
    border: none;
    border-bottom: 1px solid #eee;
  }
  
  .context-menu {
    & > div {
        &:not(.menu_divider) {
            cursor: pointer;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #333;
            padding: 0px 5px;
            height: 24px;
            max-width: 350px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            border-radius: 4px;
        }
        margin: 3px 0;
        svg {
            width: 18px;
            height: 18px;
        }
        
        mat-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
            line-height: 18px;
        }
    }
    
    &.menu_divider {
        height: 1px;
        width: 100%;
        background: #D7D7D7;
    }
  }

  .dark_menu {
    z-index: 1000;
    width: 340px;
    animation: fadeIn 0.1s ease-out;
    opacity: 1;
    display: block;
    background: #2C2C2C;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 7px;
    

    &.ai_menu {
        width: 760px;

        &.with_mistake {
            width: 960px;

            .body_left { 
                flex-shrink: 1 !important;
            }
            .body_right { 
                flex-shrink: 1 !important;
            }
        }

        .context_body {
            display: flex;
            padding: 0 16px;

            

            &.with_results {
                max-height: 50vh;
                overflow-x: hidden;
                overflow-y: auto;
            }

            .mistake {
                flex-shrink: 0;
                width: 200px;
                color: #C9C9C9;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; 
                padding: 32px 20px 32px 32px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background: #535353;
                }
            }

            .res_list {
                padding: 20px 0;
                width: 100%;
                
                .res {
                    color: #C9C9C9;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; 
                    border-radius: 3px;
                    padding: 5px 7px;
                    cursor: pointer;

                    & + .res {
                        margin-top: 10px;
                    }

                    &.active {
                        background: #202020; 
                    }
                }
            }
            
            .body_left {
                width: calc(50% - 52px);
                flex-shrink: 0;
                padding: 16px 32px 16px 20px;
                color: #C9C9C9;

                .mat-form-field-label {
                    color: #C9C9C9;
                }
                .mat-form-field-underline {
                    background-color: #535353;
                }
                .mat-form-field-ripple {
                    background-color: #777777;
                }
                .mat-input-element {
                    caret-color: #fff !important;
                }

                .char_counter_hint {
                    color: #C9C9C9;
                }
            }

            .body_right {
                width: calc(50% - 52px);
                flex-shrink: 0;
                position: relative;
                padding: 32px 20px 32px 32px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .context_footer {
                    padding: 0;
                    padding-top: 20px;
                }
                .msgs_preview {
                    .msg {
                        color: #C9C9C9;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px; 

                        b {
                            color: #FFF;
                            font-weight: 700;
                        }

                        & + .msg {
                            margin-top: 30px;
                        }
                    }

                    .request_sys {
                        margin-top: 30px;
                        color: #676666;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal; 
                        white-space: normal;
                    }
                    .form_sys {
                        margin-top: 30px;
                        .sys_str {
                            color: #676666;
                            font-size: 8px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal; 
                            caret-color: #fff;
                            white-space: normal;
                        }
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background: #535353;
                }
            }
        }
    }

    .context_head {
        padding: 16px 16px 10px 16px;
        border-bottom: 1px solid #535353;

        .cont_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #C9C9C9;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
        .cont_desc {
            color: #C9C9C9;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
        }
    }

    .context_body {
        padding: 10px 16px 16px 16px;


        &.with_results {
            max-height: 60vh;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .grouped_outlets {

            .group_items {

                .title {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 7px;
                        color: #C9C9C9;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px; /* 85.714% */ 
                    }
                }

                .items {
                    padding: 5px 0 5px 35px;

                    .item {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        
                        & > span {
                            margin-left: 10px;
                            color: #C9C9C9;
                        }
                        .outlet-line .outlet_info {
                            color: #C9C9C9 !important;
                        }
                        &.active {
                            .outlet-line .outlet_info {
                                color: #2980b9 !important;
                            }
                            & > span {
                                color: #2980b9 !important;
                            }
                        }
                        & + .item {
                            margin-top: 3px;
                        }
                    }
                }

                & + .group_items {
                    margin-top: 10px;
                }
            }
        }

        .selected_items {
            .selected_item {
                border-radius: 3px;
                background: #3A3A3A; 
                padding: 8px;

                .chann {
                    .outlet-line .outlet_info {
                        color: #FFFFFF !important;
                    }
                }

                .chann_settings {
                    display: flex;
                    align-items: flex-end;
                    color: #C9C9C9;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;

                    & > * {
                        & + * {
                            margin-left: 2.5px;
                        }
                    }

                    .time {
                        color: #C9C9C9;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        position: relative;

                        & > div {
                            color: #2F80ED;
                            cursor: pointer;
                            &.active {
                                font-weight: 500;
                            }

                        }
                        .full_width_form {
                            position: absolute;
                            left: 0;
                            top: 100%;
                        }
                    }

                    .min_int {
                        width: 30px;
                        background: transparent;
                        color: #FFF;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;

                        border: none;
                        outline: none;
                        border-radius: 0;
                        caret-color: #fff;
                        padding: 0 3px;
                        border-bottom: 1px solid #C9C9C9;
                    }
                }

                & + .selected_item {
                    margin-top: 8px;
                }
            }
        }
    }

    .context_body_desc {
        padding: 14px 0;
        color: #C9C9C9;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }

    .context_footer {
        padding: 10px 16px 16px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sys_btn {
            border-radius: 3px;
            background: #202020; 
            height: 14px; 
            display: flex;
            align-items: center;
            padding: 0 4px;
            cursor: pointer;
            color: #C9C9C9;
            font-size: 8px;
            font-style: normal;
            font-weight: 300;
            line-height: normal; 
        }

        .cntxt_btn {
            border-radius: 4px;
            background: transparent;
            color: #686868;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; 
            border: none;
            cursor: pointer;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;

            & + .cntxt_btn {
                margin-left: 10px;
            }

            &.blue {
                color: #fff;
                background: #2F80ED;
                box-shadow: 0px 1px 4px 0px #A3A3A3; 
            }
        }
    }
  }
  
  .context-menu {

    & > div {
        &:not(.search_wrp):not(.no_focus) {
            &:hover {
    
                color: #fff;
                background: #1877F2;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(2px);
            
                & > svg {
                    fill: #fff;
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
  }
  

  .mat_check_menu_item {
    padding: 0 !important;

    &.mat-menu-item {
        padding-left: 0 !important;
    }

    .mat-checkbox {
        padding: 0;
        width: 100%;

        .mat-checkbox-layout {
            width: calc(100% - 48px);
            padding: 0px 24px;
            height: 48px;
            align-items: center;

            .mat-checkbox-inner-container {
                margin-left: 0 !important;
                margin-right: 20px !important;
            }
        }
    }
  }

.small_analytics_count {
    color: #1877F2 !important;
    font-size: 10px !important;
}
.remove_all_chips_btn {
    order: 999999;
}
.start_page_chips {
    font-family: 'Roboto Condensed', sans-serif !important;
    .mat-chip-list-wrapper {
        border-radius: 17px;
        background: #fff;
        padding: 0 2px;
        margin: -2px -4px !important;
    }
    .mat-standard-chip .mat-chip-remove.mat-icon {
        color: #EDEDED !important;
        visibility: hidden !important;
        opacity: 0 !important;
        width: 10px !important;
        height: 10px !important;
        font-size: 10px !important;
        line-height: 10px !important;
        margin-left: 3px !important;
    }
    .remove_all_chips_btn {
        width: 16px;
        height: 16px;
        line-height: 16px; 
        color: #647C64;
        font-size: 16px;
        margin-right: 4px;
        cursor: pointer;
      }
    .mat-standard-chip {
        min-height: 20px;
        background: #647C64 !important;
        font-size: 12px !important;
        font-weight: 300 !important;
        line-height: 20px !important;
        color: #EDEDED !important;

        .platform_wrp app-platform {
            width: 20px !important;
            height: 20px !important;

            img,svg {
                width: 20px !important;
                height: 20px !important;
            }
        }

        .outlet-line {
 
            & > .platform_icon {
                width: 20px !important;
                height: 20px !important;

                & > app-platform {
                    width: 20px !important;
                    height: 20px !important;

                    img,svg {
                        width: 20px !important;
                        height: 20px !important;
                    }
                }
            }
            & > .channel_avatar {
                width: 20px !important;
                height: 20px !important;

                img,svg {
                    width: 20px !important;
                    height: 20px !important;
                }
            }

            .outlet_info {
                font-size: 12px !important;
                font-weight: 300 !important;
                line-height: 20px !important;
                color: #EDEDED !important;
            }
        }

        &.mat-chip-with-trailing-icon {
            padding: 0 4px 0 10px;
        }

        &:hover {
            .mat-chip-remove.mat-icon {
                visibility: visible !important;
                opacity: 1 !important;
            }
        }
    }
}

.policy_link {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
        text-decoration: none;
        color: #686868 !important;
        font-family: 'Roboto Condensed', sans-serif;
        text-align: center;
        font-size: 12px;

        &:hover {
            color: #333;
        }
    }
}